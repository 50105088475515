<template>
  <div v-if="!cleaningGroup">-</div>
  <template v-else>
    <RouterLink
      :to="{ name: 'cleaning_groups.manage', params: { id: cleaningGroup.id } }"
      class="btn-link"
    >
      {{ cleaningGroup.name }}
    </RouterLink>
    <Badge
      v-if="cleaningGroup.archived_at"
      class="badge-gray ml-2"
    >
      {{ $t("shared.archived") }}
    </Badge>
  </template>
</template>

<script lang="ts" setup>
import type { CleaningGroup } from "@/contexts/cleaning-groups/models/CleaningGroup";
import Badge from "@/contexts/shared/ui/components/badge/Badge.vue";

defineProps<{
  cleaningGroup: CleaningGroup | undefined;
}>();

const { t: $t } = useI18n();
</script>
