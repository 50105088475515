<template>
  <div class="flex items-center">
    <BookingsPaymentMethodIcon
      :method="method"
      class="mr-1 size-4"
    />
    {{ $t(`bookings.payments.method.${method}`) }}
  </div>
</template>

<script setup lang="ts">
import type { BookingPaymentMethod } from "@/contexts/bookings/models/BookingPayment";
import BookingsPaymentMethodIcon from "@/contexts/bookings/ui/components/BookingsPaymentMethodIcon.vue";

defineProps<{
  method: BookingPaymentMethod;
}>();

const { t: $t } = useI18n();
</script>
