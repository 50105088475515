<template>
  <ModalDialogHeader>
    <ModalDialogTitle>{{ $t("accommodation_contracts.create.title") }}</ModalDialogTitle>
  </ModalDialogHeader>
  <ModalDialogContent>
    <form
      class="space-y-4"
      @submit.prevent="submit"
    >
      <FormGroup :errors="createAccommodationContractRequest.errors.year">
        <FormLabel>{{ $t("accommodation_contracts.props.year") }}</FormLabel>
        <FormInput>
          <FormSelect
            v-model.number="form.year"
            :options="yearOptions"
            name="year"
          />
        </FormInput>
      </FormGroup>
      <FormGroup :errors="createAccommodationContractRequest.errors.reference_price">
        <FormLabel>{{ $t("accommodation_contracts.props.reference_price") }}</FormLabel>
        <FormInput>
          <FormFloat
            v-model="form.reference_price"
            name="reference_price"
          />
        </FormInput>
      </FormGroup>
      <FormGroup :errors="createAccommodationContractRequest.errors.notes">
        <FormLabel>{{ $t("accommodation_contracts.props.notes") }}</FormLabel>
        <FormInput>
          <FormTextarea
            v-model="form.notes"
            name="notes"
          />
        </FormInput>
      </FormGroup>
    </form>
  </ModalDialogContent>
  <ModalDialogFooter>
    <button
      class="btn-primary"
      type="submit"
      @click.prevent="submit"
    >
      {{ $t("shared.form.save") }}
    </button>
    <button
      class="btn-white"
      type="button"
      @click.prevent="$emit('close')"
    >
      {{ $t("shared.form.cancel") }}
    </button>
  </ModalDialogFooter>
</template>

<script lang="ts" setup>
import ModalDialogTitle from "@/contexts/shared/ui/components/modal/ModalDialogTitle.vue";
import ModalDialogHeader from "@/contexts/shared/ui/components/modal/ModalDialogHeader.vue";
import ModalDialogContent from "@/contexts/shared/ui/components/modal/ModalDialogContent.vue";
import ModalDialogFooter from "@/contexts/shared/ui/components/modal/ModalDialogFooter.vue";
import type { UnsavedAccommodationContract } from "@/contexts/accommodations/models/AccommodationContract";
import FormGroup from "@/contexts/shared/ui/components/form/FormGroup.vue";
import FormInput from "@/contexts/shared/ui/components/form/FormInput.vue";
import FormLabel from "@/contexts/shared/ui/components/form/FormLabel.vue";
import FormSelect from "@/contexts/shared/ui/components/form/FormSelect.vue";
import useAccommodationContracts from "@/contexts/accommodation-contracts/composables/useAccommodationContracts";
import useRequest from "@/contexts/shared/composables/useRequest";
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import type { Option } from "@/contexts/shared/models/Select";
import FormFloat from "@/contexts/shared/ui/components/form/FormFloat.vue";
import FormTextarea from "@/contexts/shared/ui/components/form/FormTextarea.vue";
import useNotification from "@/contexts/shared/composables/useNotification";

const props = defineProps<{
  accommodation: Accommodation;
}>();

const emit = defineEmits<{
  close: [];
  created: [];
}>();

const { t: $t } = useI18n();
const { createAccommodationContract } = useAccommodationContracts();
const { errorNotification, successNotification } = useNotification();

const yearOptions = computed<Option[]>(() => {
  let year = Number(new Date().getFullYear());
  return [year++, year++, year++, year++, year++].map((item) => ({ value: item, label: `${item}` }));
});
const form = ref<UnsavedAccommodationContract>({ year: new Date().getFullYear(), reference_price: 0, notes: null });

const createAccommodationContractRequest = useRequest({
  keepLoading: true,
  promise: () => createAccommodationContract(props.accommodation.id, form.value),
  onSuccess: () => {
    successNotification("accommodation_contracts.create.success");
    emit("close");
    emit("created");
  },
  onFailure: (response) => {
    if (response.status !== 422) {
      errorNotification("accommodation_contracts.create.error");
    }
  },
});

const submit = async () => {
  createAccommodationContractRequest.fetch();
};
</script>
