<template>
  <DropdownMenu>
    <template #button>
      <slot />
    </template>
    <DropdownMenuItemButton
      :disabled="!!accommodation.archived_at"
      @click="accommodationPublishToChannelsModal?.open()"
    >
      {{ $t("accommodations.actions.publish") }}
    </DropdownMenuItemButton>
    <DropdownMenuItemButton
      :disabled="!!accommodation.archived_at || accommodation.channels?.length === 0"
      @click="attachmentSyncPricesModal?.open()"
    >
      {{ $t("accommodations.actions.sync") }}
    </DropdownMenuItemButton>
    <DropdownMenuItemButton
      :disabled="!!accommodation.archived_at"
      @click="accommodationUpdateAvailabilityModal?.open()"
    >
      {{ $t("accommodations.actions.availability") }}
    </DropdownMenuItemButton>
    <DropdownMenuItemButton
      :disabled="!accommodation.default_attachment_id"
      @click="attachmentPreviewModal?.open()"
    >
      {{ $t("accommodation_attachments.preview.title") }}
    </DropdownMenuItemButton>
    <DropdownMenuItemButton
      v-if="!accommodation.archived_at"
      :disabled="archiveAccommodationRequest.isLoading"
      @click="archiveAccommodationRequest.fetch()"
    >
      {{ $t("accommodations.actions.archive") }}
    </DropdownMenuItemButton>
    <DropdownMenuItemButton
      v-else
      :disabled="unarchiveAccommodationRequest.isLoading"
      @click="unarchiveAccommodationRequest.fetch()"
    >
      {{ $t("accommodations.actions.unarchive") }}
    </DropdownMenuItemButton>
    <DropdownMenuItemButton
      class="text-red-600 hover:text-red-700"
      @click="accommodationDeleteModal?.open()"
    >
      {{ $t("accommodations.actions.delete") }}
    </DropdownMenuItemButton>
  </DropdownMenu>
  <ModalDialog
    v-if="accommodation.default_attachment_id"
    ref="attachmentPreviewModal"
    v-slot="{ close }"
    size="3xl"
  >
    <AccommodationAttachmentsPreviewModal
      :id="accommodation.default_attachment_id"
      :accommodation-id="accommodation.id"
      @close="close"
    />
  </ModalDialog>
  <ModalDialog
    ref="accommodationPublishToChannelsModal"
    v-slot="{ close }"
    size="3xl"
  >
    <AccommodationsPublishToChannelsModal
      :accommodation="accommodation"
      @close="close"
      @published="$emit('published')"
    />
  </ModalDialog>
  <ModalDialog
    ref="attachmentSyncPricesModal"
    v-slot="{ close }"
    size="medium"
  >
    <AccommodationsSyncPricesToChannelsModal
      :accommodation="accommodation"
      @close="close"
      @updated="$emit('syncPricing')"
    />
  </ModalDialog>
  <ModalDialog
    ref="accommodationUpdateAvailabilityModal"
    v-slot="{ close }"
    size="medium"
  >
    <AccommodationsUpdateAvailabilityModal
      :accommodation="accommodation"
      @close="close"
      @updated="$emit('updatedAvailability')"
    />
  </ModalDialog>
  <ModalDialog
    ref="accommodationDeleteModal"
    v-slot="{ close }"
  >
    <AccommodationsDeleteModal
      :accommodation="accommodation"
      @close="close"
      @deleted="$emit('deleted')"
    />
  </ModalDialog>
</template>

<script lang="ts" setup>
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import DropdownMenuItemButton from "@/contexts/shared/ui/components/dropdown/DropdownMenuItemButton.vue";
import DropdownMenu from "@/contexts/shared/ui/components/dropdown/DropdownMenu.vue";
import ModalDialog from "@/contexts/shared/ui/components/modal/ModalDialog.vue";
import AccommodationAttachmentsPreviewModal from "@/contexts/accommodation-attachments/ui/modals/AccommodationAttachmentsPreviewModal.vue";
import AccommodationsDeleteModal from "@/contexts/accommodations/ui/modals/AccommodationsDeleteModal.vue";
import useNotification from "@/contexts/shared/composables/useNotification";
import useAccommodations from "@/contexts/accommodations/composables/useAccommodations";
import useRequest from "@/contexts/shared/composables/useRequest";
import AccommodationsPublishToChannelsModal from "@/contexts/accommodations/ui/modals/AccommodationsPublishToChannelsModal.vue";
import AccommodationsSyncPricesToChannelsModal from "@/contexts/accommodations/ui/modals/AccommodationsSyncPricesToChannelsModal.vue";
import AccommodationsUpdateAvailabilityModal from "@/contexts/accommodations/ui/modals/AccommodationsUpdateAvailabilityModal.vue";

const props = defineProps<{
  accommodation: Accommodation;
}>();

const emit = defineEmits<{
  archived: [];
  unarchived: [];
  deleted: [];
  published: [];
  updatedAvailability: [];
  syncPricing: [];
}>();

const { t: $t } = useI18n();
const { successNotification, errorNotification } = useNotification();
const { archiveAccommodation, unarchiveAccommodation } = useAccommodations();

const attachmentPreviewModal = ref<InstanceType<typeof ModalDialog>>();
const accommodationDeleteModal = ref<InstanceType<typeof ModalDialog>>();
const attachmentSyncPricesModal = ref<InstanceType<typeof ModalDialog>>();
const accommodationUpdateAvailabilityModal = ref<InstanceType<typeof ModalDialog>>();
const accommodationPublishToChannelsModal = ref<InstanceType<typeof ModalDialog>>();

const archiveAccommodationRequest = useRequest({
  promise: () => archiveAccommodation(props.accommodation.id),
  onSuccess: () => {
    successNotification("accommodations.archive.success");
    emit("archived");
  },
  onFailure: () => {
    errorNotification("accommodations.archive.error");
  },
});
const unarchiveAccommodationRequest = useRequest({
  promise: () => unarchiveAccommodation(props.accommodation.id),
  onSuccess: () => {
    successNotification("accommodations.unarchive.success");
    emit("unarchived");
  },
  onFailure: () => {
    errorNotification("accommodations.unarchive.error");
  },
});
</script>
