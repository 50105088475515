import type { Accommodation, UnsavedAccommodation } from "@/contexts/accommodations/models/Accommodation";
import AccommodationsClient from "@/clients/accommodations";
import CreateAccommodationFactory from "@/contexts/accommodations/composables/factories/CreateAccommodationFactory";
import type { Pagination } from "@/contexts/shared/models/Pagination";
import type { SearchAccommodationsCriteria } from "@/contexts/accommodations/models/SearchAccommodationsCriteria";
import SearchAccommodationsFactory from "@/contexts/accommodations/composables/factories/SearchAccommodationsFactory";
import ShowAccommodationFactory from "@/contexts/accommodations/composables/factories/ShowAccommodationFactory";
import UpdateAccommodationDetailsFactory from "@/contexts/accommodations/composables/factories/UpdateAccommodationDetailsFactory";
import UpdateAccommodationAddressFactory from "@/contexts/accommodations/composables/factories/UpdateAccommodationAddressFactory";
import type { CalendarAccommodationsCriteria } from "@/contexts/accommodations/models/CalendarAccommodationsCriteria";
import CalendarAccommodationFactory from "@/contexts/accommodations/composables/factories/CalendarAccommodationFactory";
import type { AccommodationCalendar } from "@/contexts/accommodations/models/AccommodationCalendar";
import type { BookingsAccommodationsCriteria } from "@/contexts/accommodations/models/BookingsAccommodationsCriteria";
import type { Booking } from "@/contexts/bookings/models/Booking";
import BookingsAccommodationFactory from "@/contexts/accommodations/composables/factories/BookingsAccommodationFactory";
import UpdateAccommodationBedroomsFactory from "@/contexts/accommodations/composables/factories/UpdateAccommodationBedroomsFactory";
import type { UnsavedAccommodationBathroom, UnsavedAccommodationBedroom, UnsavedAccommodationKitchen } from "@/contexts/accommodations/models/AccommodationRooms";
import UpdateAccommodationBathroomsFactory from "@/contexts/accommodations/composables/factories/UpdateAccommodationBathroomsFactory";
import UpdateAccommodationKitchensFactory from "@/contexts/accommodations/composables/factories/UpdateAccommodationKitchensFactory";
import type { AccommodationFacilities } from "@/contexts/accommodations/models/AccommodationFacilities";
import FacilitiesAccommodationFactory from "@/contexts/accommodations/composables/factories/FacilitiesAccommodationFactory";
import UpdateAccommodationFacilitiesFactory from "@/contexts/accommodations/composables/factories/UpdateAccommodationFacilitiesFactory";
import type { AccommodationChannelRequirements, AccommodationChannelType } from "@/contexts/accommodations/models/AccommodationChannel";
import api from "@/libs/api";
import type { Option } from "@/contexts/shared/models/Select";
import AccommodationChannelsProvider from "@/contexts/accommodations/providers/accommodationChannelsProvider";
import ShowAccommodationChannelFactory from "@/contexts/accommodations/composables/factories/GetAccommodationChannelFactory";
import type { UnsavedAccommodationDescription } from "@/contexts/accommodations/models/AccommodationDescription";
import UpdateAccommodationDescriptionsFactory from "@/contexts/accommodations/composables/factories/UpdateAccommodationDescriptionsFactory";
import UpdateAccommodationExtrasFactory from "@/contexts/accommodations/composables/factories/UpdateAccommodationExtrasFactory";
import type { UnsavedAccommodationExtra } from "@/contexts/accommodations/models/AccommodationExtra";
import type { SearchAccommodationsPricesCriteria } from "@/contexts/accommodations/models/SearchAccommodationsPricesCriteria";
import SearchAccommodationsPricesFactory from "@/contexts/accommodations/composables/factories/SearchAccommodationsPricesFactory";
import type { AccommodationPrices } from "@/contexts/accommodations/models/AccommodationPrices";
import type { UpdateAccommodationsPrices } from "@/contexts/accommodations/models/UpdateAccommodationsPrices";
import UpdateAccommodationPricesFactory from "@/contexts/accommodations/composables/factories/UpdateAccommodationPricesFactory";
import SyncAccommodationPricesFactory from "@/contexts/accommodations/composables/factories/SyncAccommodationPricesFactory";
import UpdateAccommodationAvailabilityFactory from "@/contexts/accommodations/composables/factories/UpdateAccommodationAvailabilityFactory";

export default function useAccommodations() {
  const { t: $t } = useI18n();

  const empty: UnsavedAccommodation = {
    name: "",
    acronym: "",
    type: "villa",
    featured: false,
    registration_code: null,
    land_registration_code: null,
    indoor_area: null,
    total_area: null,
    floors: null,
    facilities: [],
    check_in_time_from: null,
    check_in_time_to: null,
    check_out_time: null,
    owner_id: null,
    cleaning_group_id: null,
    bathrooms: [],
    bedrooms: [],
    kitchens: [],
    notes: null,
    street: null,
    city: null,
    postal_code: null,
    country_code: null,
    latitude: null,
    longitude: null,
  };

  const searchAccommodations = async (criteria: SearchAccommodationsCriteria, cursor?: string): Promise<Pagination<Accommodation>> => {
    const { data, response } = await api.GET("/accommodations", { params: { query: SearchAccommodationsFactory.toSearchAccommodationsRequest(criteria, cursor) } });
    return SearchAccommodationsFactory.fromSearchAccommodationsResponse(data, response.headers);
  };
  const searchAccommodationsPrices = async (criteria: SearchAccommodationsPricesCriteria): Promise<AccommodationPrices[]> => {
    const { data } = await api.GET("/accommodations/prices", { params: { query: SearchAccommodationsPricesFactory.toSearchAccommodationsPricesRequest(criteria) } });
    return SearchAccommodationsPricesFactory.fromSearchAccommodationsPricesResponse(data);
  };
  const updateAccommodationsPrices = async (prices: UpdateAccommodationsPrices): Promise<void> => {
    await api.POST("/accommodations/prices", { body: UpdateAccommodationPricesFactory.toUpdateAccommodationPricesFactory(prices) });
  };
  const syncAccommodationPrices = async (id: Accommodation["id"], from: Date | null, to: Date | null) => {
    await api.POST("/accommodations/{accommodation}/prices/sync", { params: { path: { accommodation: id } }, body: SyncAccommodationPricesFactory.toSyncAccommodationPricesRequest(from, to) });
  };
  const createAccommodation = async (accommodation: UnsavedAccommodation): Promise<void> => {
    return await AccommodationsClient.createAccommodation(CreateAccommodationFactory.toCreateAccommodationRequest(accommodation));
  };
  const showAccommodationChannel = async (id: Accommodation["id"], channel: AccommodationChannelType): Promise<Record<AccommodationChannelRequirements, boolean>> => {
    const { data } = await api.GET("/accommodations/{accommodation}/channels/{channel}", { params: { path: { accommodation: id, channel } } });
    return ShowAccommodationChannelFactory.fromShowAccommodationChannelResponse(data);
  };
  const createAccommodationChannel = async (id: Accommodation["id"], channel: AccommodationChannelType): Promise<void> => {
    await api.POST("/accommodations/{accommodation}/channels/{channel}", { params: { path: { accommodation: id, channel } } });
  };
  const showAccommodation = async (id: Accommodation["id"]): Promise<Accommodation> => {
    const { data } = await api.GET("/accommodations/{accommodation}", { params: { path: { accommodation: id } } });
    return ShowAccommodationFactory.fromShowAccommodationResponse(data);
  };
  const calendarAccommodation = async (id: Accommodation["id"], criteria: CalendarAccommodationsCriteria): Promise<AccommodationCalendar> => {
    const { data } = await api.GET("/accommodations/{accommodation}/calendar", { params: { path: { accommodation: id }, query: CalendarAccommodationFactory.toCalendarAccommodationRequest(criteria) } });
    return CalendarAccommodationFactory.fromCalendarAccommodationResponse(data);
  };
  const bookingsAccommodation = async (id: Accommodation["id"], criteria: BookingsAccommodationsCriteria, cursor?: string): Promise<Pagination<Booking>> => {
    const { data, response } = await api.GET("/accommodations/{accommodation}/bookings", { params: { path: { accommodation: id }, query: BookingsAccommodationFactory.toBookingsAccommodationRequest(criteria, cursor) } });
    return BookingsAccommodationFactory.fromBookingsAccommodationResponse(data, response.headers);
  };
  const updateAccommodationDetails = async (id: Accommodation["id"], accommodation: UnsavedAccommodation): Promise<Accommodation> => {
    const { data } = await api.PUT("/accommodations/{accommodation}/details", { params: { path: { accommodation: id } }, body: UpdateAccommodationDetailsFactory.toUpdateAccommodationDetailsRequest(accommodation) });
    return UpdateAccommodationDetailsFactory.fromUpdateAccommodationDetailsResponse(data);
  };
  const updateAccommodationAvailability = async (id: Accommodation["id"], from: Date | null, to: Date | null, status: "available" | "locked_by_owner" | "locked_by_manager"): Promise<void> => {
    await api.POST("/accommodations/{accommodation}/availability", { params: { path: { accommodation: id } }, body: UpdateAccommodationAvailabilityFactory.toUpdateAccommodationAvailabilityRequest(from, to, status) });
  };
  const updateAccommodationAddress = async (id: Accommodation["id"], accommodation: UnsavedAccommodation): Promise<Accommodation> => {
    const { data } = await api.PUT("/accommodations/{accommodation}/address", { params: { path: { accommodation: id } }, body: UpdateAccommodationAddressFactory.toUpdateAccommodationAddressRequest(accommodation) });
    return UpdateAccommodationAddressFactory.fromUpdateAccommodationAddressResponse(data);
  };
  const updateAccommodationBedrooms = async (id: Accommodation["id"], bedrooms: UnsavedAccommodationBedroom[]): Promise<Accommodation> => {
    const { data } = await api.PUT("/accommodations/{accommodation}/bedrooms", { params: { path: { accommodation: id } }, body: UpdateAccommodationBedroomsFactory.toUpdateAccommodationBedroomsRequest(bedrooms) });
    return UpdateAccommodationBedroomsFactory.fromUpdateAccommodationBedroomsResponse(data);
  };
  const updateAccommodationBathrooms = async (id: Accommodation["id"], bathrooms: UnsavedAccommodationBathroom[]): Promise<Accommodation> => {
    const { data } = await api.PUT("/accommodations/{accommodation}/bathrooms", { params: { path: { accommodation: id } }, body: UpdateAccommodationBathroomsFactory.toUpdateAccommodationBathroomsRequest(bathrooms) });
    return UpdateAccommodationBathroomsFactory.fromUpdateAccommodationBathroomsResponse(data);
  };
  const updateAccommodationKitchens = async (id: Accommodation["id"], kitchens: UnsavedAccommodationKitchen[]): Promise<Accommodation> => {
    const { data } = await api.PUT("/accommodations/{accommodation}/kitchens", { params: { path: { accommodation: id } }, body: UpdateAccommodationKitchensFactory.toUpdateAccommodationKitchensRequest(kitchens) });
    return UpdateAccommodationKitchensFactory.fromUpdateAccommodationKitchensResponse(data);
  };
  const updateAccommodationFacilities = async (id: Accommodation["id"], facilities: string[]): Promise<Accommodation> => {
    const { data } = await api.PUT("/accommodations/{accommodation}/facilities", { params: { path: { accommodation: id } }, body: UpdateAccommodationFacilitiesFactory.toUpdateAccommodationFacilitiesRequest(facilities) });
    return UpdateAccommodationFacilitiesFactory.fromUpdateAccommodationFacilitiesResponse(data);
  };
  const updateAccommodationDescriptions = async (id: Accommodation["id"], descriptions: UnsavedAccommodationDescription[]): Promise<Accommodation> => {
    const { data } = await api.PUT("/accommodations/{accommodation}/descriptions", { params: { path: { accommodation: id } }, body: UpdateAccommodationDescriptionsFactory.toUpdateAccommodationDescriptionsRequest(descriptions) });
    return UpdateAccommodationDescriptionsFactory.fromUpdateAccommodationDescriptionsResponse(data);
  };
  const updateAccommodationExtras = async (id: Accommodation["id"], extras: UnsavedAccommodationExtra[]): Promise<Accommodation> => {
    const { data } = await api.PUT("/accommodations/{accommodation}/extras", { params: { path: { accommodation: id } }, body: UpdateAccommodationExtrasFactory.toUpdateAccommodationExtrasRequest(extras) });
    return UpdateAccommodationExtrasFactory.fromUpdateAccommodationExtrasResponse(data);
  };
  const archiveAccommodation = async (id: Accommodation["id"]): Promise<void> => {
    return await AccommodationsClient.archiveAccommodation(id);
  };
  const unarchiveAccommodation = async (id: Accommodation["id"]): Promise<void> => {
    return await AccommodationsClient.unarchiveAccommodation(id);
  };
  const deleteAccommodation = async (id: Accommodation["id"]): Promise<void> => {
    return await AccommodationsClient.deleteAccommodation(id);
  };
  const facilitiesAccommodation = async (): Promise<AccommodationFacilities> => {
    return FacilitiesAccommodationFactory.fromFacilitiesAccommodationResponse(await AccommodationsClient.facilitiesAccommodation());
  };

  const accommodationChannelsOptions: Option[] = AccommodationChannelsProvider.get().map((item) => ({ value: item, label: $t(`accommodations.channels.${item}`) }));

  return {
    empty,
    showAccommodation,
    createAccommodation,
    deleteAccommodation,
    searchAccommodations,
    archiveAccommodation,
    calendarAccommodation,
    bookingsAccommodation,
    unarchiveAccommodation,
    facilitiesAccommodation,
    syncAccommodationPrices,
    showAccommodationChannel,
    updateAccommodationExtras,
    updateAccommodationsPrices,
    createAccommodationChannel,
    searchAccommodationsPrices,
    updateAccommodationDetails,
    updateAccommodationAddress,
    updateAccommodationBedrooms,
    updateAccommodationKitchens,
    updateAccommodationBathrooms,
    updateAccommodationFacilities,
    updateAccommodationDescriptions,
    updateAccommodationAvailability,
    accommodationChannelsOptions,
  };
}
