<template>
  <ModalDialogHeader>
    <ModalDialogTitle>{{ $t("accommodations.update.bedrooms") }}</ModalDialogTitle>
  </ModalDialogHeader>
  <ModalDialogContent>
    <form @submit.prevent="submit">
      <div class="space-y-2.5">
        <template
          v-for="(item, index) in input"
          :key="index"
        >
          <div class="relative gap-2 rounded border border-gray-200 p-3">
            <ButtonWithIcon
              class="absolute right-0 top-0 m-1 p-2"
              icon="trash-can-solid"
              @click="removeBedroom(index)"
            />
            <div class="text-sm font-medium text-gray-800">{{ $t(`accommodations.manage.bedrooms.name.bedroom`, { number: index + 1 }) }}</div>
            <div class="mt-2 grid gap-2 md:grid-cols-4">
              <div class="grid gap-2">
                <FormGroup :errors="updateAccommodationBedroomsRequest.errors?.[`bedrooms.${index}.type`]">
                  <FormLabel>{{ $t("accommodations.manage.bedrooms.props.type") }}</FormLabel>
                  <FormInput>
                    <FormSelect
                      v-model="input[index].type"
                      :options="bedroomTypeOptions"
                      name="type"
                    />
                  </FormInput>
                </FormGroup>
                <FormGroup :errors="updateAccommodationBedroomsRequest.errors?.[`bedrooms.${index}.floor`]">
                  <FormLabel>{{ $t("accommodations.manage.bedrooms.props.floor") }}</FormLabel>
                  <FormInput>
                    <FormInteger
                      v-model="input[index].floor"
                      name="floor"
                    />
                  </FormInput>
                </FormGroup>
              </div>
              <div class="grid gap-2 md:col-span-3 md:grid-cols-3">
                <FormGroup :errors="updateAccommodationBedroomsRequest.errors?.[`bedrooms.${index}.single_beds`]">
                  <FormLabel>{{ $t("accommodations.manage.bedrooms.props.single_beds") }}</FormLabel>
                  <FormInput>
                    <FormInteger
                      v-model="input[index].single_beds"
                      name="single_beds"
                    />
                  </FormInput>
                </FormGroup>
                <FormGroup :errors="updateAccommodationBedroomsRequest.errors?.[`bedrooms.${index}.double_beds`]">
                  <FormLabel>{{ $t("accommodations.manage.bedrooms.props.double_beds") }}</FormLabel>
                  <FormInput>
                    <FormInteger
                      v-model="input[index].double_beds"
                      name="double_beds"
                    />
                  </FormInput>
                </FormGroup>
                <FormGroup :errors="updateAccommodationBedroomsRequest.errors?.[`bedrooms.${index}.suite_beds`]">
                  <FormLabel>{{ $t("accommodations.manage.bedrooms.props.suite_beds") }}</FormLabel>
                  <FormInput>
                    <FormInteger
                      v-model="input[index].suite_beds"
                      name="suite_beds"
                    />
                  </FormInput>
                </FormGroup>
                <FormGroup :errors="updateAccommodationBedroomsRequest.errors?.[`bedrooms.${index}.bunk_beds`]">
                  <FormLabel>{{ $t("accommodations.manage.bedrooms.props.bunk_beds") }}</FormLabel>
                  <FormInput>
                    <FormInteger
                      v-model="input[index].bunk_beds"
                      name="bunk_beds"
                    />
                  </FormInput>
                </FormGroup>
              </div>
            </div>
          </div>
        </template>
        <div class="flex justify-center md:col-span-2">
          <ButtonWithIcon
            class="btn-primary"
            icon="plus-solid"
            @click="addBedroom"
          >
            {{ $t("accommodations.manage.bedrooms.add") }}
          </ButtonWithIcon>
        </div>
      </div>
      <input
        class="hidden"
        type="submit"
      />
    </form>
  </ModalDialogContent>
  <ModalDialogFooter>
    <button
      class="btn-primary"
      type="submit"
      @click.prevent="submit"
    >
      {{ $t("shared.form.save") }}
    </button>
    <button
      class="btn-white"
      type="button"
      @click.prevent="$emit('close')"
    >
      {{ $t("shared.form.cancel") }}
    </button>
  </ModalDialogFooter>
</template>

<script lang="ts" setup>
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import ModalDialogHeader from "@/contexts/shared/ui/components/modal/ModalDialogHeader.vue";
import ModalDialogContent from "@/contexts/shared/ui/components/modal/ModalDialogContent.vue";
import ModalDialogTitle from "@/contexts/shared/ui/components/modal/ModalDialogTitle.vue";
import ModalDialogFooter from "@/contexts/shared/ui/components/modal/ModalDialogFooter.vue";
import type { AccommodationBedroom, UnsavedAccommodationBedroom } from "@/contexts/accommodations/models/AccommodationRooms";
import ButtonWithIcon from "@/contexts/shared/ui/components/button/ButtonWithIcon.vue";
import { cloneDeep } from "lodash-es";
import FormGroup from "@/contexts/shared/ui/components/form/FormGroup.vue";
import FormLabel from "@/contexts/shared/ui/components/form/FormLabel.vue";
import FormSelect from "@/contexts/shared/ui/components/form/FormSelect.vue";
import FormInput from "@/contexts/shared/ui/components/form/FormInput.vue";
import type { Option } from "@/contexts/shared/models/Select";
import FormInteger from "@/contexts/shared/ui/components/form/FormInteger.vue";
import useRequest from "@/contexts/shared/composables/useRequest";
import useAccommodations from "@/contexts/accommodations/composables/useAccommodations";
import useNotification from "@/contexts/shared/composables/useNotification";

const props = defineProps<{
  accommodation: Accommodation;
  bedrooms: AccommodationBedroom[];
}>();

const emit = defineEmits<{
  close: [];
  updated: [value: Accommodation];
}>();

const { t: $t } = useI18n();
const { updateAccommodationBedrooms } = useAccommodations();
const { successNotification, errorNotification } = useNotification();

onMounted(() => {
  input.value = cloneDeep(props.bedrooms);
});

const input = ref<UnsavedAccommodationBedroom[]>([]);
const bedroomTypeOptions = computed<Option[]>(() => ["bedroom", "living_room", "other"].map((item) => ({ value: item, label: $t(`accommodations.manage.bedrooms.type.${item}`) })));

const updateAccommodationBedroomsRequest = useRequest({
  promise: () => updateAccommodationBedrooms(props.accommodation.id, input.value),
  onSuccess: (response) => {
    successNotification("accommodations.update.success");
    emit("updated", response);
    emit("close");
  },
  onFailure: (response) => {
    if (response.status !== 422) {
      errorNotification("accommodations.update.error");
    }
  },
});

const addBedroom = () => {
  input.value.push({ type: "bedroom", floor: null, single_beds: 0, double_beds: 0, suite_beds: 0, bunk_beds: 0 });
};
const removeBedroom = (index: number) => {
  input.value.splice(index, 1);
};
const submit = async () => {
  await updateAccommodationBedroomsRequest.fetch();
};
</script>
