<template>
  <ModalDialog
    ref="modal"
    :parent-route="{ name: 'cleaning_groups' }"
    route
  >
    <ModalDialogHeader>
      <ModalDialogTitle>{{ $t("cleaning_groups.create.title") }}</ModalDialogTitle>
    </ModalDialogHeader>
    <ModalDialogContent>
      <CleaningGroupsForm
        v-model="cleaningGroup"
        :errors="createCleaningGroupRequest.errors"
        @submit="submit"
      />
    </ModalDialogContent>
    <ModalDialogFooter>
      <button
        :disabled="createCleaningGroupRequest.isLoading"
        class="btn-primary"
        type="submit"
        @click.prevent="submit"
      >
        {{ $t("shared.form.save") }}
      </button>
      <button
        class="btn-white"
        type="button"
        @click.prevent="modal?.close()"
      >
        {{ $t("shared.form.cancel") }}
      </button>
    </ModalDialogFooter>
  </ModalDialog>
</template>

<script lang="ts" setup>
import ModalDialog from "@/contexts/shared/ui/components/modal/ModalDialog.vue";
import ModalDialogContent from "@/contexts/shared/ui/components/modal/ModalDialogContent.vue";
import ModalDialogHeader from "@/contexts/shared/ui/components/modal/ModalDialogHeader.vue";
import ModalDialogTitle from "@/contexts/shared/ui/components/modal/ModalDialogTitle.vue";
import CleaningGroupsForm from "@/contexts/cleaning-groups/ui/components/CleaningGroupsForm.vue";
import ModalDialogFooter from "@/contexts/shared/ui/components/modal/ModalDialogFooter.vue";
import useCleaningGroups from "@/contexts/cleaning-groups/composables/useCleaningGroups";
import useNotification from "@/contexts/shared/composables/useNotification";
import type { UnsavedCleaningGroup } from "@/contexts/cleaning-groups/models/CleaningGroup";
import useRequest from "@/contexts/shared/composables/useRequest";

const emit = defineEmits<{
  fetchList: [];
}>();

const { t: $t } = useI18n();
const { createCleaningGroup } = useCleaningGroups();
const { successNotification, errorNotification } = useNotification();

const modal = ref<InstanceType<typeof ModalDialog>>();

const cleaningGroup = ref<UnsavedCleaningGroup>({ name: "" });

const createCleaningGroupRequest = useRequest({
  promise: () => createCleaningGroup(cleaningGroup.value),
  onSuccess: () => {
    successNotification("cleaning_groups.create.success");
    emit("fetchList");
    modal.value?.close();
  },
  onFailure: (response) => {
    if (response.status !== 422) {
      errorNotification("cleaning_groups.create.error");
    }
  },
});

const submit = async () => {
  await createCleaningGroupRequest.fetch();
};
</script>
