<template>
  <div class="rounded-md border border-gray-200 p-4">
    <div class="flex items-center space-x-4 px-2 pb-2">
      <FontAwesomeIcon
        :icon="icon"
        class="h-5 w-5 fill-primary"
      />
      <div class="font-semibold text-gray-800">{{ $t(title) }}</div>
    </div>
    <template v-if="description">
      <div class="pb-2 text-sm text-gray-700">{{ $t(description) }}</div>
    </template>
    <div class="mt-1.5 flex flex-col space-y-0.5">
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
import FontAwesomeIcon, { type FontAwesomeIconTypes } from "@/contexts/shared/ui/components/icon/FontAwesomeIcon.vue";

defineProps<{
  icon: FontAwesomeIconTypes;
  title: string;
  description?: string;
}>();

const { t: $t } = useI18n();
</script>
