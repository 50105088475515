<template>
  <input
    :id="id"
    v-model="input"
    :name="name"
    :value="value"
    class="h-4 w-4 rounded-2xl border-gray-300 text-primary shadow-sm focus:ring-primary"
    type="radio"
    @click.stop
  />
</template>

<script lang="ts" setup>
const props = defineProps<{
  id: string;
  name: string;
  value: string | number | boolean;
  modelValue: string | number | boolean | undefined;
}>();

const emit = defineEmits<{
  "update:modelValue": [value: string | number | boolean | undefined];
}>();

const input = computed<string | number | boolean | undefined>({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});
</script>
