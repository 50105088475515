import { useI18n } from "vue-i18n";
import { toast } from "vue-sonner";

export default function useNotification() {
  const { t: $t } = useI18n();

  const simpleNotification = (message: string, description?: string): void => {
    toast.info($t(message), { description: description ? $t(description) : undefined });
  };
  const successNotification = (message: string, description?: string): void => {
    toast.success($t(message), { description: description ? $t(description) : undefined });
  };
  const errorNotification = (message: string, description?: string): void => {
    toast.error($t(message), { description: description ? $t(description) : undefined });
  };

  return {
    simpleNotification,
    successNotification,
    errorNotification,
  };
}
