<template>
  <ListTable>
    <ListTableHeader>
      <ListTableHeaderCell primary>
        {{ $t("owners.props.name") }}
      </ListTableHeaderCell>
      <ListTableHeaderCell>
        {{ $t("owners.props.email") }}
      </ListTableHeaderCell>
      <ListTableHeaderCell optional>
        {{ $t("owners.props.created_at") }}
      </ListTableHeaderCell>
      <ListTableHeaderCell optional>
        {{ $t("owners.props.updated_at") }}
      </ListTableHeaderCell>
      <ListTableHeaderCell small />
    </ListTableHeader>
    <ListTableBody>
      <template
        v-for="owner in owners"
        :key="owner.id"
      >
        <ListTableBodyRowLink :to="{ name: 'owners.manage', params: { id: owner.id } }">
          <ListTableBodyCell>{{ owner.name }}</ListTableBodyCell>
          <ListTableBodyCell>{{ owner.email }}</ListTableBodyCell>
          <ListTableBodyCell optional>
            {{ owner.created_at.toLocaleString() }}
          </ListTableBodyCell>
          <ListTableBodyCell optional>
            {{ owner.updated_at.toLocaleString() }}
          </ListTableBodyCell>
          <ListTableBodyCell
            :padding="false"
            :truncate="false"
            class="pl-2.5"
          >
            <OwnersDropdown
              :owner="owner"
              @deleted="$emit('deleted')"
              @updated="$emit('updated')"
            />
          </ListTableBodyCell>
        </ListTableBodyRowLink>
      </template>
    </ListTableBody>
  </ListTable>
</template>

<script lang="ts" setup>
import type { Owner } from "@/contexts/owners/models/Owner";
import ListTable from "@/contexts/shared/ui/components/list/ListTable.vue";
import ListTableHeaderCell from "@/contexts/shared/ui/components/list/ListTableHeaderCell.vue";
import ListTableBodyCell from "@/contexts/shared/ui/components/list/ListTableBodyCell.vue";
import ListTableBodyRowLink from "@/contexts/shared/ui/components/list/ListTableBodyRowLink.vue";
import ListTableHeader from "@/contexts/shared/ui/components/list/ListTableHeader.vue";
import ListTableBody from "@/contexts/shared/ui/components/list/ListTableBody.vue";
import OwnersDropdown from "@/contexts/owners/ui/components/OwnersDropdown.vue";

defineProps<{
  owners: Owner[];
}>();

defineEmits<{
  updated: [];
  deleted: [];
}>();

const { t: $t } = useI18n();
</script>
