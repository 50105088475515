<template>
  <form
    class="space-y-3.5"
    @submit.prevent="submit"
  >
    <FormGroup :errors="errors.name">
      <FormLabel>{{ $t("owners.props.name") }}</FormLabel>
      <FormInput>
        <FormText
          v-model="input.name"
          name="name"
        />
      </FormInput>
    </FormGroup>
    <FormGroup :errors="errors.email">
      <FormLabel>{{ $t("owners.props.email") }}</FormLabel>
      <FormInput>
        <FormText
          v-model="input.email"
          name="email"
          type="email"
        />
      </FormInput>
    </FormGroup>
    <FormGroup
      v-if="type === 'create'"
      :errors="errors.password"
    >
      <FormLabel>{{ $t("owners.props.password") }}</FormLabel>
      <FormInput>
        <FormPassword
          v-model="input.password"
          name="password"
        />
      </FormInput>
    </FormGroup>
    <FormGroup :errors="errors.language">
      <FormLabel>{{ $t("owners.props.language") }}</FormLabel>
      <FormInput>
        <FormSelect
          v-model="input.language"
          :options="useLanguage().languageOptions"
          name="language"
        />
      </FormInput>
    </FormGroup>
    <FormGroup :errors="errors.phones">
      <FormLabel>{{ $t("owners.props.phone") }}</FormLabel>
      <FormInput>
        <FormText
          v-model="input.phones[0]"
          name="phone"
        />
      </FormInput>
    </FormGroup>
    <FormGroup :errors="errors.notes">
      <FormLabel>{{ $t("owners.props.notes") }}</FormLabel>
      <FormInput>
        <FormTextarea
          v-model="input.notes"
          name="notes"
        />
      </FormInput>
    </FormGroup>
    <input
      class="hidden"
      type="submit"
    />
  </form>
</template>

<script lang="ts" setup>
import type { UnsavedOwner } from "@/contexts/owners/models/Owner";
import type { RequestErrors } from "@/contexts/shared/composables/useRequest";
import FormText from "@/contexts/shared/ui/components/form/FormText.vue";
import FormInput from "@/contexts/shared/ui/components/form/FormInput.vue";
import FormLabel from "@/contexts/shared/ui/components/form/FormLabel.vue";
import FormGroup from "@/contexts/shared/ui/components/form/FormGroup.vue";
import FormSelect from "@/contexts/shared/ui/components/form/FormSelect.vue";
import FormTextarea from "@/contexts/shared/ui/components/form/FormTextarea.vue";
import useLanguage from "@/contexts/shared/composables/useLanguage";
import FormPassword from "@/contexts/shared/ui/components/form/FormPassword.vue";

const props = defineProps<{
  modelValue: UnsavedOwner;
  errors: RequestErrors<UnsavedOwner>;
  type: "create" | "update";
}>();

const emit = defineEmits<{
  submit: [];
  "update:modelValue": [value: UnsavedOwner];
}>();

const { t: $t } = useI18n();

const input = computed<UnsavedOwner>({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});

const submit = () => {
  emit("submit");
};
</script>
