<template>
  <form
    class="space-y-3.5"
    @submit.prevent="submit"
  >
    <FormGroup :errors="errors.name">
      <FormLabel>{{ $t("accounts.props.name") }}</FormLabel>
      <FormInput>
        <FormText
          v-model="input.name"
          name="name"
        />
      </FormInput>
    </FormGroup>
    <FormGroup :errors="errors.email">
      <FormLabel>{{ $t("accounts.props.email") }}</FormLabel>
      <FormInput>
        <FormText
          v-model="input.email"
          name="email"
          type="email"
        />
      </FormInput>
    </FormGroup>
    <input
      class="hidden"
      type="submit"
    />
  </form>
</template>

<script lang="ts" setup>
import FormGroup from "@/contexts/shared/ui/components/form/FormGroup.vue";
import FormLabel from "@/contexts/shared/ui/components/form/FormLabel.vue";
import FormInput from "@/contexts/shared/ui/components/form/FormInput.vue";
import FormText from "@/contexts/shared/ui/components/form/FormText.vue";
import type { UnsavedCreateAccount } from "@/contexts/accounts/models/Account";
import type { RequestErrors } from "@/contexts/shared/composables/useRequest";

const props = defineProps<{
  modelValue: UnsavedCreateAccount;
  errors: RequestErrors<UnsavedCreateAccount>;
}>();

const emit = defineEmits<{
  submit: [];
  "update:modelValue": [value: UnsavedCreateAccount];
}>();

const { t: $t } = useI18n();

const input = computed<UnsavedCreateAccount>({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});

const submit = () => {
  emit("submit");
};
</script>
