<template>
  <div v-if="!accommodation">-</div>
  <template v-else>
    <RouterLink
      :to="{ name: 'accommodations.manage', params: { id: accommodation.id } }"
      class="btn-link"
    >
      {{ accommodation.acronym }}
    </RouterLink>
    <Badge
      v-if="accommodation.archived_at"
      class="badge-gray ml-2"
    >
      {{ $t("shared.archived") }}
    </Badge>
  </template>
</template>

<script lang="ts" setup>
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import Badge from "@/contexts/shared/ui/components/badge/Badge.vue";

defineProps<{
  accommodation: Accommodation | undefined;
}>();

const { t: $t } = useI18n();
</script>
