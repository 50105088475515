<template>
  <ModalDialogHeader>
    <ModalDialogTitle>{{ $t("accounts.services.update.title") }}</ModalDialogTitle>
  </ModalDialogHeader>
  <ModalDialogContent>
    <AccountServicesForm
      v-model="form"
      :errors="updateAccountServiceRequest.errors"
      @submit="submit"
    />
  </ModalDialogContent>
  <ModalDialogFooter>
    <button
      :disabled="updateAccountServiceRequest.isLoading"
      class="btn-primary"
      type="submit"
      @click.prevent="submit"
    >
      {{ $t("shared.form.save") }}
    </button>
    <button
      class="btn-white"
      type="button"
      @click.prevent="$emit('close')"
    >
      {{ $t("shared.form.cancel") }}
    </button>
  </ModalDialogFooter>
</template>

<script lang="ts" setup>
import ModalDialogContent from "@/contexts/shared/ui/components/modal/ModalDialogContent.vue";
import ModalDialogFooter from "@/contexts/shared/ui/components/modal/ModalDialogFooter.vue";
import ModalDialogHeader from "@/contexts/shared/ui/components/modal/ModalDialogHeader.vue";
import ModalDialogTitle from "@/contexts/shared/ui/components/modal/ModalDialogTitle.vue";
import useNotification from "@/contexts/shared/composables/useNotification";
import useRequest from "@/contexts/shared/composables/useRequest";
import useAccountServices from "@/contexts/accounts/composables/useAccountServices";
import type { AccountService, UnsavedAccountService } from "@/contexts/accounts/models/AccountService";
import AccountServicesForm from "@/contexts/accounts/ui/components/AccountServicesForm.vue";
import { cloneDeep } from "lodash-es";

const props = defineProps<{
  accountService: AccountService;
}>();

const emit = defineEmits<{
  close: [];
  updated: [];
}>();

const { t: $t } = useI18n();
const { updateAccountService } = useAccountServices();
const { successNotification, errorNotification } = useNotification();

const form = ref<UnsavedAccountService>(cloneDeep({ ...props.accountService }));

const updateAccountServiceRequest = useRequest({
  promise: () => updateAccountService(props.accountService.id, form.value),
  onSuccess: () => {
    successNotification("accounts.services.update.success");
    emit("close");
    emit("updated");
  },
  onFailure: (response) => {
    if (response.status !== 422) {
      errorNotification("accounts.update.error");
    }
  },
});

const submit = async () => {
  await updateAccountServiceRequest.fetch();
};
</script>
