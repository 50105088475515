<template>
  <form
    class="space-y-3.5"
    @submit.prevent="submit"
  >
    <FormGroup :errors="errors.name">
      <FormLabel>{{ $t("cleaning_groups.props.name") }}</FormLabel>
      <FormInput>
        <FormText
          v-model="input.name"
          name="name"
        />
      </FormInput>
    </FormGroup>
    <input
      class="hidden"
      type="submit"
    />
  </form>
</template>

<script lang="ts" setup>
import FormGroup from "@/contexts/shared/ui/components/form/FormGroup.vue";
import FormLabel from "@/contexts/shared/ui/components/form/FormLabel.vue";
import FormInput from "@/contexts/shared/ui/components/form/FormInput.vue";
import FormText from "@/contexts/shared/ui/components/form/FormText.vue";
import type { UnsavedCleaningGroup } from "@/contexts/cleaning-groups/models/CleaningGroup";
import type { RequestErrors } from "@/contexts/shared/composables/useRequest";

const props = defineProps<{
  modelValue: UnsavedCleaningGroup;
  errors: RequestErrors<UnsavedCleaningGroup>;
}>();

const emit = defineEmits<{
  submit: [];
  "update:modelValue": [value: UnsavedCleaningGroup];
}>();

const { t: $t } = useI18n();

const input = computed<UnsavedCleaningGroup>({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});

const submit = () => {
  emit("submit");
};
</script>
