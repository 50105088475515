<template>
  <template v-if="(channels?.length ?? 0) > 0">
    <div class="flex items-center gap-1.5">
      <template
        v-for="channel in channels"
        :key="channel.id"
      >
        <ProviderIcon
          :icon="channel.channel"
          class="size-5"
        />
      </template>
    </div>
  </template>
  <template v-else>
    {{ $t("accommodations.manage.channels.empty") }}
  </template>
</template>

<script setup lang="ts">
import type { AccommodationChannel } from "@/contexts/accommodations/models/AccommodationChannel";
import ProviderIcon from "@/contexts/shared/ui/components/icon/ProviderIcon.vue";

defineProps<{
  channels: AccommodationChannel[] | undefined;
}>();

const { t: $t } = useI18n();
</script>
