<template>
  <ListTable>
    <ListTableHeader>
      <ListTableHeaderCell primary>
        {{ $t("accommodations.props.name") }}
      </ListTableHeaderCell>
      <ListTableHeaderCell>
        {{ $t("accommodations.props.channels") }}
      </ListTableHeaderCell>
      <ListTableHeaderCell>
        {{ $t("accommodations.props.capacity") }}
      </ListTableHeaderCell>
      <ListTableHeaderCell optional>
        {{ $t("accommodations.props.bedrooms") }}
      </ListTableHeaderCell>
      <ListTableHeaderCell optional>
        {{ $t("accommodations.props.bathrooms") }}
      </ListTableHeaderCell>
      <ListTableHeaderCell optional>
        {{ $t("accommodations.props.created_at") }}
      </ListTableHeaderCell>
      <ListTableHeaderCell optional>
        {{ $t("accommodations.props.updated_at") }}
      </ListTableHeaderCell>
      <ListTableHeaderCell small />
    </ListTableHeader>
    <ListTableBody>
      <template
        v-for="accommodation in accommodations"
        :key="accommodation.id"
      >
        <ListTableBodyRowLink :to="{ name: 'accommodations.manage', params: { id: accommodation.id } }">
          <ListTableBodyCell>
            {{ accommodation.acronym }}
            <Badge
              v-if="accommodation.archived_at"
              class="badge-gray ml-2"
            >
              {{ $t("shared.archived") }}
            </Badge>
          </ListTableBodyCell>
          <ListTableBodyCell>
            <AccommodationsChannels :channels="accommodation.channels" />
          </ListTableBodyCell>
          <ListTableBodyCell>
            {{ $t("accommodations.manage.capacity", accommodation?.capacity ?? 0) }}
          </ListTableBodyCell>
          <ListTableBodyCell optional>
            {{ $t("accommodations.manage.bedrooms.count.bedrooms", accommodation?.bedrooms_count ?? 0) }}
          </ListTableBodyCell>
          <ListTableBodyCell optional>
            {{ $t("accommodations.manage.bathrooms.count", accommodation?.bathrooms_count ?? 0) }}
          </ListTableBodyCell>
          <ListTableBodyCell optional>
            {{ accommodation.created_at.toLocaleString() }}
          </ListTableBodyCell>
          <ListTableBodyCell optional>
            {{ accommodation.updated_at.toLocaleString() }}
          </ListTableBodyCell>
          <ListTableBodyCell
            :padding="false"
            :truncate="false"
            class="pl-2.5"
          >
            <AccommodationsDropdown
              :accommodation="accommodation"
              @archived="$emit('fetchList')"
              @unarchived="$emit('fetchList')"
              @deleted="$emit('fetchList')"
              @published="$emit('fetchList')"
            />
          </ListTableBodyCell>
        </ListTableBodyRowLink>
      </template>
    </ListTableBody>
  </ListTable>
</template>

<script lang="ts" setup>
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import ListTable from "@/contexts/shared/ui/components/list/ListTable.vue";
import ListTableHeader from "@/contexts/shared/ui/components/list/ListTableHeader.vue";
import ListTableHeaderCell from "@/contexts/shared/ui/components/list/ListTableHeaderCell.vue";
import ListTableBody from "@/contexts/shared/ui/components/list/ListTableBody.vue";
import ListTableBodyRowLink from "@/contexts/shared/ui/components/list/ListTableBodyRowLink.vue";
import ListTableBodyCell from "@/contexts/shared/ui/components/list/ListTableBodyCell.vue";
import AccommodationsDropdown from "@/contexts/accommodations/ui/components/AccommodationsDropdown.vue";
import Badge from "@/contexts/shared/ui/components/badge/Badge.vue";
import AccommodationsChannels from "@/contexts/accommodations/ui/components/AccommodationsChannels.vue";

defineProps<{
  accommodations: Accommodation[];
}>();

defineEmits<{
  fetchList: [];
}>();

const { t: $t } = useI18n();
</script>
