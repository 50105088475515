<template>
  <div class="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
    <div class="grid gap-4 md:col-span-2 md:grid-cols-2">
      <FormGroup :errors="errors.check_in">
        <FormLabel>{{ $t("bookings.props.check_in") }}</FormLabel>
        <FormInput>
          <FormDatePicker
            v-model="modelValue.check_in"
            :min-date="new Date()"
            @update:model-value="modelValue.check_out = null"
          />
        </FormInput>
      </FormGroup>
      <FormGroup :errors="errors.check_out">
        <FormLabel>{{ $t("bookings.props.check_out") }}</FormLabel>
        <FormInput>
          <FormDatePicker
            v-model="modelValue.check_out"
            :disabled="!modelValue.check_in"
            :min-date="mixCheckOutDate"
          />
        </FormInput>
      </FormGroup>
      <div class="rounded bg-gray-100 p-3 text-sm md:col-span-2">
        <span class="mr-1.5 text-gray-700">{{ $t("bookings.total.title") }}</span>
        <span class="font-semibold text-gray-800">{{ $t("bookings.total.nights", totalNights) }}</span>
      </div>
    </div>
    <FormGroup :errors="errors.adults">
      <FormLabel>{{ $t("bookings.props.adults") }}</FormLabel>
      <FormInput>
        <FormInteger
          v-model="modelValue.adults"
          :min="1"
          name="adults"
        />
      </FormInput>
    </FormGroup>
    <FormGroup :errors="errors.children">
      <FormLabel>{{ $t("bookings.props.children") }}</FormLabel>
      <FormInput>
        <FormInteger
          v-model="modelValue.children"
          :min="0"
          name="children"
        />
      </FormInput>
    </FormGroup>
    <FormGroup :errors="errors.language">
      <FormLabel>{{ $t("bookings.props.language") }}</FormLabel>
      <FormInput>
        <FormSelect
          v-model="modelValue.language"
          :options="useLanguage().languageOptions"
          name="language"
        />
      </FormInput>
    </FormGroup>
  </div>
</template>

<script lang="ts" setup>
import type { UnsavedBooking } from "@/contexts/bookings/models/Booking";
import FormDatePicker from "@/contexts/shared/ui/components/form/FormDatePicker.vue";
import FormGroup from "@/contexts/shared/ui/components/form/FormGroup.vue";
import FormLabel from "@/contexts/shared/ui/components/form/FormLabel.vue";
import FormInput from "@/contexts/shared/ui/components/form/FormInput.vue";
import FormInteger from "@/contexts/shared/ui/components/form/FormInteger.vue";
import FormSelect from "@/contexts/shared/ui/components/form/FormSelect.vue";
import type { RequestErrors } from "@/contexts/shared/composables/useRequest";
import useDayjs from "@/contexts/shared/composables/useDayjs";
import useLanguage from "@/contexts/shared/composables/useLanguage";

defineProps<{
  errors: RequestErrors<UnsavedBooking>;
}>();

const modelValue = defineModel<UnsavedBooking>({ required: true });

const { t: $t } = useI18n();

const dayjs = useDayjs();

const mixCheckOutDate = computed<Date>(() => (modelValue.value.check_in ? dayjs(modelValue.value.check_in).add(1, "day").toDate() : new Date()));
const totalNights = computed<number>(() => (modelValue.value.check_in && modelValue.value.check_out ? dayjs(modelValue.value.check_out).diff(dayjs(modelValue.value.check_in), "days") : 0));
</script>
