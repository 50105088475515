<template>
  <FilterItemButton @clear="clear">
    <div
      v-if="!empty"
      class="max-w-[15rem] truncate whitespace-nowrap pl-2 text-primary"
    >
      {{ selectedAccommodation?.acronym }}
    </div>
  </FilterItemButton>
  <FilterContent v-slot="{ close }">
    <div class="flex items-center space-x-2">
      <AccommodationsSelect
        v-model="accommodationId"
        class="w-full"
        custom-width
      />
    </div>
    <button
      class="btn-primary flex w-full items-center py-1"
      type="submit"
      @click="apply(close)"
    >
      <div class="mx-auto">
        {{ $t("shared.filters.apply") }}
      </div>
    </button>
  </FilterContent>
</template>

<script lang="ts" setup>
import type { ComputedRef, WritableComputedRef } from "vue";
import FilterItemButton from "@/contexts/shared/ui/components/filter/FilterItemButton.vue";
import FilterContent from "@/contexts/shared/ui/components/filter/FilterContent.vue";
import AccommodationsSelect from "@/contexts/accommodations/ui/components/AccommodationsSelect.vue";
import useRequest from "@/contexts/shared/composables/useRequest";
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import useAccommodations from "@/contexts/accommodations/composables/useAccommodations";

const empty = inject<ComputedRef<boolean>>("empty");
const input = inject<WritableComputedRef<string | undefined>>("input") as WritableComputedRef<string | undefined>;

const accommodationId = ref<string | null>(input.value ?? null);
const selectedAccommodation = ref<Accommodation>();

const { t: $t } = useI18n();
const { showAccommodation } = useAccommodations();

onMounted(() => {
  if (accommodationId.value) {
    showSelectedAccommodationsRequest.fetch();
  }
});
watch(input, (value) => {
  accommodationId.value = value ?? null;
});

const apply = (close: () => void) => {
  input.value = accommodationId.value ?? undefined;
  if (accommodationId.value) {
    showSelectedAccommodationsRequest.fetch();
  } else {
    selectedAccommodation.value = undefined;
  }
  close();
};
const clear = () => {
  input.value = undefined;
};

const showSelectedAccommodationsRequest = useRequest<Accommodation>({
  promise: () => showAccommodation(String(accommodationId.value)),
  onSuccess: (response) => (selectedAccommodation.value = response),
});
</script>
