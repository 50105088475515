<template>
  <div class="space-y-4 md:grid md:grid-cols-2 md:gap-4 md:space-y-0">
    <FormGroup
      :errors="errors.street"
      class="md:col-span-2"
    >
      <FormLabel>{{ $t("accommodations.props.street") }}</FormLabel>
      <FormInput>
        <FormText
          v-model="input.street"
          name="street"
        />
      </FormInput>
    </FormGroup>
    <FormGroup :errors="errors.city">
      <FormLabel>{{ $t("accommodations.props.city") }}</FormLabel>
      <FormInput>
        <FormText
          v-model="input.city"
          name="city"
        />
      </FormInput>
    </FormGroup>
    <FormGroup :errors="errors.postal_code">
      <FormLabel>{{ $t("accommodations.props.postal_code") }}</FormLabel>
      <FormInput>
        <FormText
          v-model="input.postal_code"
          name="postal_code"
        />
      </FormInput>
    </FormGroup>
    <FormGroup :errors="errors.country_code">
      <FormLabel>{{ $t("accommodations.props.country_code") }}</FormLabel>
      <FormInput>
        <FormSelect
          v-model="input.country_code"
          :options="useCountry().countryOptions"
          name="country_code"
        />
      </FormInput>
    </FormGroup>
    <div class="space-y-4 md:col-span-2 md:grid md:grid-cols-2 md:gap-4 md:space-y-0">
      <FormGroup :errors="errors.latitude">
        <FormLabel>{{ $t("accommodations.props.latitude") }}</FormLabel>
        <FormInput>
          <FormText
            v-model.number="input.latitude"
            name="latitude"
          />
        </FormInput>
      </FormGroup>
      <FormGroup :errors="errors.longitude">
        <FormLabel>{{ $t("accommodations.props.longitude") }}</FormLabel>
        <FormInput>
          <FormText
            v-model.number="input.longitude"
            name="longitude"
          />
        </FormInput>
      </FormGroup>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { UnsavedAccommodation } from "@/contexts/accommodations/models/Accommodation";
import type { RequestErrors } from "@/contexts/shared/composables/useRequest";
import FormGroup from "@/contexts/shared/ui/components/form/FormGroup.vue";
import FormLabel from "@/contexts/shared/ui/components/form/FormLabel.vue";
import FormSelect from "@/contexts/shared/ui/components/form/FormSelect.vue";
import FormInput from "@/contexts/shared/ui/components/form/FormInput.vue";
import FormText from "@/contexts/shared/ui/components/form/FormText.vue";
import useCountry from "@/contexts/shared/composables/useCountry";

const props = defineProps<{
  modelValue: UnsavedAccommodation;
  errors: RequestErrors<UnsavedAccommodation>;
}>();

const emit = defineEmits<{
  "update:modelValue": [value: UnsavedAccommodation];
}>();

const { t: $t } = useI18n();

const input = computed<UnsavedAccommodation>({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});
</script>
