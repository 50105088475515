<template>
  <div class="flex gap-4">
    <template
      v-for="(quickFilter, index) in filters"
      :key="index"
    >
      <button
        type="button"
        class="btn-white w-full px-2.5 py-3 font-normal aria-[current=true]:text-primary aria-[current=true]:ring-2 aria-[current=true]:ring-primary"
        :aria-current="quickFilter.active.value"
        @click.prevent="quickFilter.action"
      >
        {{ $t(quickFilter.label) }}
      </button>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { QuickFilter } from "@/contexts/shared/models/QuickFilter";

defineProps<{
  filters: QuickFilter[];
}>();

const { t: $t } = useI18n();
</script>
