<template>
  <div class="grid grid-cols-3 gap-2">
    <FormGroup :errors="errors?.[`extras.${index}.name`]">
      <FormLabel>{{ $t("accommodations.extras.props.name") }}</FormLabel>
      <FormInput>
        <FormSelect
          v-model="modelValue.name"
          :disabled="!!modelValue.id"
          :options="nameOptions"
          name="name"
        />
      </FormInput>
    </FormGroup>
    <FormGroup :errors="errors?.[`extras.${index}.payment_time`]">
      <FormLabel>{{ $t("accommodations.extras.props.payment_time") }}</FormLabel>
      <FormInput>
        <FormSelect
          v-model="modelValue.payment_time"
          :options="paymentTimeOptions"
          name="payment_time"
        />
      </FormInput>
    </FormGroup>
    <FormGroup :errors="errors?.[`extras.${index}.type`]">
      <FormLabel>{{ $t("accommodations.extras.props.type") }}</FormLabel>
      <FormInput>
        <FormSelect
          v-model="modelValue.type"
          :options="typeOptions"
          name="type"
        />
      </FormInput>
    </FormGroup>
    <div class="col-span-3 grid grid-cols-2 gap-2">
      <div class="grid grid-cols-3 gap-2">
        <div class="col-span-2">
          <FormGroup :errors="errors?.[`extras.${index}.price.price`]">
            <FormLabel>{{ $t("accommodations.extras.props.price") }}</FormLabel>
            <FormInput>
              <FormAmount
                v-model="modelValue.price.price"
                name="price.price"
              />
            </FormInput>
          </FormGroup>
        </div>
        <FormGroup :errors="errors?.[`extras.${index}.price.type`]">
          <FormLabel>{{ $t("accommodations.extras.props.price_type") }}</FormLabel>
          <FormInput>
            <FormSelect
              v-model="modelValue.price.type"
              :options="priceTypeOptions"
              name="price.type"
            />
          </FormInput>
        </FormGroup>
      </div>
      <FormGroup :errors="errors?.[`extras.${index}.mode`]">
        <FormLabel>{{ $t("accommodations.extras.props.mode") }}</FormLabel>
        <FormInput>
          <FormSelect
            v-model="modelValue.mode"
            :options="modeOptions"
            name="mode"
          />
        </FormInput>
      </FormGroup>
    </div>
    <FormGroup :errors="errors?.[`extras.${index}.maximum_units`]">
      <FormLabel>{{ $t("accommodations.extras.props.maximum_units") }}</FormLabel>
      <FormInput>
        <FormInteger
          v-model="modelValue.maximum_units"
          :min="0"
          name="maximum_units"
        />
      </FormInput>
    </FormGroup>
    <div class="col-span-3 grid grid-cols-2 place-items-stretch gap-2">
      <div class="mb-auto grid gap-2">
        <FormSwitchWithLabel
          :id="`number_of_nights_${index}`"
          :model-value="!!modelValue.conditions.number_of_nights"
          name="number_of_nights"
          @update:model-value="modelValue.conditions.number_of_nights = $event ? { from_stay_length_in_nights: null, to_stay_length_in_nights: null } : null"
        >
          {{ $t("accommodations.extras.props.conditions_number_of_nights") }}
        </FormSwitchWithLabel>
        <div
          v-if="modelValue.conditions.number_of_nights"
          class="grid gap-2"
        >
          <FormGroup :errors="errors?.[`extras.${index}.conditions.number_of_nights.from_stay_length_in_nights`]">
            <FormLabel>{{ $t("accommodations.extras.props.conditions_number_of_nights_from_stay_length_in_nights") }}</FormLabel>
            <FormInput>
              <FormInteger
                v-model="modelValue.conditions.number_of_nights.from_stay_length_in_nights"
                :min="0"
                name="from_stay_length_in_nights"
              />
            </FormInput>
          </FormGroup>
          <FormGroup :errors="errors?.[`extras.${index}.conditions.number_of_nights.to_stay_length_in_nights`]">
            <FormLabel>{{ $t("accommodations.extras.props.conditions_number_of_nights_to_stay_length_in_nights") }}</FormLabel>
            <FormInput>
              <FormInteger
                v-model="modelValue.conditions.number_of_nights.to_stay_length_in_nights"
                :min="0"
                name="to_stay_length_in_nights"
              />
            </FormInput>
          </FormGroup>
        </div>
      </div>
      <div class="mb-auto grid gap-2">
        <FormSwitchWithLabel
          :id="`applicable_period_${index}`"
          :model-value="!!modelValue.conditions.applicable_period"
          name="applicable_period"
          @update:model-value="modelValue.conditions.applicable_period = $event ? { only_applicable_from: null, only_applicable_until: null } : null"
        >
          {{ $t("accommodations.extras.props.conditions_applicable_period") }}
        </FormSwitchWithLabel>
        <div
          v-if="modelValue.conditions.applicable_period"
          class="grid gap-2"
        >
          <FormGroup :errors="errors?.[`extras.${index}.conditions.applicable_period.only_applicable_from`]">
            <FormLabel>{{ $t("accommodations.extras.props.conditions_applicable_period_only_applicable_from") }}</FormLabel>
            <FormInput>
              <FormDatePicker
                v-model="modelValue.conditions.applicable_period.only_applicable_from as Date | null"
                :max-date="modelValue.conditions.applicable_period.only_applicable_until ?? undefined"
                name="only_applicable_from"
              />
            </FormInput>
          </FormGroup>
          <FormGroup :errors="errors?.[`extras.${index}.conditions.applicable_period.only_applicable_until`]">
            <FormLabel>{{ $t("accommodations.extras.props.conditions_applicable_period_only_applicable_until") }}</FormLabel>
            <FormInput>
              <FormDatePicker
                v-model="modelValue.conditions.applicable_period.only_applicable_until as Date | null"
                :min-date="modelValue.conditions.applicable_period.only_applicable_from ?? undefined"
                name="only_applicable_until"
              />
            </FormInput>
          </FormGroup>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { UnsavedAccommodationExtra } from "@/contexts/accommodations/models/AccommodationExtra";
import type { Option } from "@/contexts/shared/models/Select";
import accommodationExtraModeProvider from "@/contexts/accommodations/providers/accommodationExtraModeProvider";
import accommodationExtraNameProvider from "@/contexts/accommodations/providers/accommodationExtraNameProvider";
import accommodationExtraPaymentTimeProvider from "@/contexts/accommodations/providers/accommodationExtraPaymentTimeProvider";
import accommodationExtraPriceTypeProvider from "@/contexts/accommodations/providers/accommodationExtraPriceTypeProvider";
import FormGroup from "@/contexts/shared/ui/components/form/FormGroup.vue";
import FormSelect from "@/contexts/shared/ui/components/form/FormSelect.vue";
import FormLabel from "@/contexts/shared/ui/components/form/FormLabel.vue";
import FormInput from "@/contexts/shared/ui/components/form/FormInput.vue";
import accommodationExtraTypeProvider from "@/contexts/accommodations/providers/accommodationExtraTypeProvider";
import FormInteger from "@/contexts/shared/ui/components/form/FormInteger.vue";
import FormAmount from "@/contexts/shared/ui/components/form/FormAmount.vue";
import FormSwitchWithLabel from "@/contexts/shared/ui/components/form/FormSwitchWithLabel.vue";
import FormDatePicker from "@/contexts/shared/ui/components/form/FormDatePicker.vue";

const props = defineProps<{
  index: number;
  input: UnsavedAccommodationExtra[];
  errors: Partial<Record<string | number | symbol, any[]>>;
}>();

const { t: $t } = useI18n();

const modelValue = defineModel<UnsavedAccommodationExtra>({
  default: {
    id: undefined,
    name: "final_cleaning",
    mode: "per_unit",
    type: "selectable",
    payment_time: "on_booking",
    price: { price: 0, type: "fixed" },
    conditions: null,
    maximum_units: null,
  },
});

const modeOptions = computed<Option[]>(() => accommodationExtraModeProvider.get().map((item) => ({ label: $t(`accommodations.extras.mode.${item}`), value: item })));
const nameOptions = computed<Option[]>(() => accommodationExtraNameProvider.get().map((item) => ({ label: $t(`accommodations.extras.name.${item}`), value: item, disabled: props.input.some((i) => i.name === item) })));
const paymentTimeOptions = computed<Option[]>(() => accommodationExtraPaymentTimeProvider.get().map((item) => ({ label: $t(`accommodations.extras.payment_time.${item}`), value: item })));
const priceTypeOptions = computed<Option[]>(() => accommodationExtraPriceTypeProvider.get().map((item) => ({ label: $t(`accommodations.extras.price_type.${item}`), value: item })));
const typeOptions = computed<Option[]>(() => accommodationExtraTypeProvider.get().map((item) => ({ label: $t(`accommodations.extras.type.${item}`), value: item })));
</script>
