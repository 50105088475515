<template>
  {{ $t(`booking_imports.status.${status}`) }}
  <template v-if="warnings">
    <span class="ml-2.5">
      <Badge
        class="badge-orange cursor-pointer"
        @click="bookingImportsWarningsModal?.open()"
      >
        {{ warnings?.length ?? 0 }}
      </Badge>
    </span>
    <teleport to="body">
      <ModalDialog
        ref="bookingImportsWarningsModal"
        v-slot="{ close }"
        size="xl"
      >
        <BookingImportsWarningsModal
          :warnings="warnings"
          @close="close"
        />
      </ModalDialog>
    </teleport>
  </template>
</template>

<script setup lang="ts">
import type { BookingImportStatusType } from "@/contexts/booking-imports/models/BookingImport";
import Badge from "@/contexts/shared/ui/components/badge/Badge.vue";
import ModalDialog from "@/contexts/shared/ui/components/modal/ModalDialog.vue";
import BookingImportsWarningsModal from "@/contexts/booking-imports/ui/modals/BookingImportsWarningsModal.vue";

defineProps<{
  status: BookingImportStatusType;
  warnings: string[] | undefined;
}>();

const { t: $t } = useI18n();

const bookingImportsWarningsModal = ref<InstanceType<typeof ModalDialog>>();
</script>
