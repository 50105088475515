<template>
  <ModalDialogHeader>
    <ModalDialogTitle>{{ $t("accounts.integrations.manage.title") }}</ModalDialogTitle>
  </ModalDialogHeader>
  <ModalDialogContent>
    <form
      class="space-y-6"
      @submit.prevent="submit"
    >
      <template
        v-for="(credential, index) in credentials"
        :key="index"
      >
        <FormGroup :errors="errors?.[`credentials.${credential}`] || errors.credentials">
          <FormLabel>{{ $t(`accounts.integrations.manage.credentials.form.${credential}`) }}</FormLabel>
          <FormInput>
            <FormText
              v-model="form.credentials[credential]"
              :name="credential"
            />
          </FormInput>
        </FormGroup>
        <input
          class="hidden"
          type="submit"
        />
      </template>
    </form>
  </ModalDialogContent>
  <ModalDialogFooter>
    <button
      :disabled="isLoading"
      class="btn-primary"
      type="submit"
      @click.prevent="submit"
    >
      {{ $t("shared.form.save") }}
    </button>
    <button
      class="btn-white"
      type="button"
      @click.prevent="$emit('close')"
    >
      {{ $t("shared.form.cancel") }}
    </button>
  </ModalDialogFooter>
</template>

<script lang="ts" setup>
import ModalDialogContent from "@/contexts/shared/ui/components/modal/ModalDialogContent.vue";
import ModalDialogHeader from "@/contexts/shared/ui/components/modal/ModalDialogHeader.vue";
import ModalDialogTitle from "@/contexts/shared/ui/components/modal/ModalDialogTitle.vue";
import ModalDialogFooter from "@/contexts/shared/ui/components/modal/ModalDialogFooter.vue";
import type { AccountIntegration, AccountIntegrationProvider, UnsavedAccountIntegration } from "@/contexts/accounts/models/AccountIntegration";
import FormInput from "@/contexts/shared/ui/components/form/FormInput.vue";
import FormGroup from "@/contexts/shared/ui/components/form/FormGroup.vue";
import FormLabel from "@/contexts/shared/ui/components/form/FormLabel.vue";
import FormText from "@/contexts/shared/ui/components/form/FormText.vue";
import type { RequestErrors } from "@/contexts/shared/composables/useRequest";

const props = defineProps<{
  provider: AccountIntegrationProvider;
  credentials: string[];
  integration: AccountIntegration | undefined;
  isLoading: boolean;
  errors: RequestErrors;
}>();

const emit = defineEmits<{
  close: [];
  submit: [value: UnsavedAccountIntegration];
}>();

const { t: $t } = useI18n();

const form = ref<UnsavedAccountIntegration>({ provider: props.provider, credentials: props.integration?.credentials ?? {}, enabled: props.integration?.enabled ?? true });

const submit = () => {
  emit("submit", form.value);
};
</script>
