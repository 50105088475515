<template>
  {{ $t(`shared.providers.${provider}`) }}
</template>

<script setup lang="ts">
import type { BookingImportProvider } from "@/contexts/booking-imports/models/BookingImport";

defineProps<{
  provider: BookingImportProvider;
}>();

const { t: $t } = useI18n();
</script>
