<template>
  <ModalDialogHeader>
    <ModalDialogTitle>{{ $t("owner_bank_accounts.create.title") }}</ModalDialogTitle>
  </ModalDialogHeader>
  <ModalDialogContent>
    <OwnerBankAccountsDetailsForm
      v-model="ownerBankAccount"
      :errors="createOwnerBankAccountRequest.errors"
      type="create"
      @submit="submit"
    />
  </ModalDialogContent>
  <ModalDialogFooter>
    <button
      :disabled="createOwnerBankAccountRequest.isLoading"
      class="btn-primary"
      type="submit"
      @click.prevent="submit"
    >
      {{ $t("shared.form.save") }}
    </button>
    <button
      class="btn-white"
      type="button"
      @click.prevent="$emit('close')"
    >
      {{ $t("shared.form.cancel") }}
    </button>
  </ModalDialogFooter>
</template>

<script setup lang="ts">
import ModalDialogHeader from "@/contexts/shared/ui/components/modal/ModalDialogHeader.vue";
import ModalDialogContent from "@/contexts/shared/ui/components/modal/ModalDialogContent.vue";
import ModalDialogTitle from "@/contexts/shared/ui/components/modal/ModalDialogTitle.vue";
import ModalDialogFooter from "@/contexts/shared/ui/components/modal/ModalDialogFooter.vue";
import OwnerBankAccountsDetailsForm from "@/contexts/owner-bank-accounts/ui/components/OwnerBankAccountsDetailsForm.vue";
import useNotification from "@/contexts/shared/composables/useNotification";
import useRequest from "@/contexts/shared/composables/useRequest";
import type { OwnerBankAccount, UnsavedOwnerBankAccount } from "@/contexts/owner-bank-accounts/models/OwnerBankAccount";
import useOwnerBankAccounts from "@/contexts/owner-bank-accounts/composables/useOwnerBankAccounts";

const props = defineProps<{
  ownerId: string;
}>();

const emit = defineEmits<{
  close: [];
  created: [value: OwnerBankAccount];
}>();

const { t: $t } = useI18n();
const { createOwnerBankAccount } = useOwnerBankAccounts();
const { successNotification, errorNotification } = useNotification();

const ownerBankAccount = ref<UnsavedOwnerBankAccount>({ address: "", country_code: "es", id_number: "", bank_name: "", bank_account: "", bank_swift_bic: "" });

const createOwnerBankAccountRequest = useRequest({
  promise: () => createOwnerBankAccount(props.ownerId, ownerBankAccount.value),
  onSuccess: (response) => {
    successNotification("owner_bank_accounts.create.success");
    emit("close");
    emit("created", response);
  },
  onFailure: (response) => {
    if (response.status !== 422) {
      errorNotification("owner_bank_accounts.create.error");
    }
  },
});

const submit = async () => {
  await createOwnerBankAccountRequest.fetch();
};
</script>
