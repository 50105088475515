<template>
  <ModalDialog
    ref="modal"
    :parent-route="{ name: 'owners' }"
    route
  >
    <ModalDialogHeader>
      <ModalDialogTitle>{{ $t("owners.create.title") }}</ModalDialogTitle>
    </ModalDialogHeader>
    <ModalDialogContent>
      <OwnersDetailsForm
        v-model="owner"
        :errors="createOwnerRequest.errors"
        type="create"
        @submit="submit"
      />
    </ModalDialogContent>
    <ModalDialogFooter>
      <button
        :disabled="createOwnerRequest.isLoading"
        class="btn-primary"
        type="submit"
        @click.prevent="submit"
      >
        {{ $t("shared.form.save") }}
      </button>
      <button
        class="btn-white"
        type="button"
        @click.prevent="modal?.close()"
      >
        {{ $t("shared.form.cancel") }}
      </button>
    </ModalDialogFooter>
  </ModalDialog>
</template>

<script lang="ts" setup>
import ModalDialog from "@/contexts/shared/ui/components/modal/ModalDialog.vue";
import ModalDialogContent from "@/contexts/shared/ui/components/modal/ModalDialogContent.vue";
import ModalDialogHeader from "@/contexts/shared/ui/components/modal/ModalDialogHeader.vue";
import ModalDialogTitle from "@/contexts/shared/ui/components/modal/ModalDialogTitle.vue";
import ModalDialogFooter from "@/contexts/shared/ui/components/modal/ModalDialogFooter.vue";
import useNotification from "@/contexts/shared/composables/useNotification";
import useRequest from "@/contexts/shared/composables/useRequest";
import useOwners from "@/contexts/owners/composables/useOwners";
import type { UnsavedOwner } from "@/contexts/owners/models/Owner";
import OwnersDetailsForm from "@/contexts/owners/ui/components/OwnersDetailsForm.vue";

const emit = defineEmits<{
  fetchList: [];
}>();

const { t: $t } = useI18n();
const { createOwner } = useOwners();
const { successNotification, errorNotification } = useNotification();

const modal = ref<InstanceType<typeof ModalDialog>>();

const owner = ref<UnsavedOwner>({ name: "", email: "", password: "", language: "", phones: [], notes: "" });

const createOwnerRequest = useRequest({
  promise: () => createOwner(owner.value),
  onSuccess: () => {
    successNotification("owners.create.success");
    emit("fetchList");
    modal.value?.close();
  },
  onFailure: (response) => {
    if (response.status !== 422) {
      errorNotification("owners.create.error");
    }
  },
});

const submit = async () => {
  await createOwnerRequest.fetch();
};
</script>
