<template>
  <DashboardHeader>
    <div class="text-xl font-semibold text-gray-800">
      {{ $t("settings.account.services.title") }}
    </div>
    <DashboardHeaderActions>
      <ButtonWithIcon
        class="btn-primary"
        icon="plus-solid"
        @click="createAccountServiceModal?.open()"
      >
        {{ $t("accounts.services.create.title") }}
      </ButtonWithIcon>
    </DashboardHeaderActions>
  </DashboardHeader>
  <BreadcrumbGroup>
    <BreadcrumbItem :to="{ name: 'settings' }">
      {{ $t("settings.title") }}
    </BreadcrumbItem>
    <BreadcrumbItem :to="{ name: 'settings.account' }">
      {{ $t("settings.account.title") }}
    </BreadcrumbItem>
    <BreadcrumbItem :to="{ name: 'settings.account.services' }">
      {{ $t("settings.account.services.title") }}
    </BreadcrumbItem>
  </BreadcrumbGroup>
  <div class="space-y-3">
    <template v-if="searchAccountServicesRequest.isLoading">
      <LoadingSection />
    </template>
    <template v-else-if="searchAccountServicesRequest.hasErrors">
      <ListErrorState />
    </template>
    <template v-else-if="searchAccountServicesRequest.data.data.length === 0">
      <ListEmptyState
        description="accounts.services.empty.description"
        icon="table-list-solid"
        title="accounts.services.empty.title"
      >
        <div class="space-x-5">
          <ButtonWithIcon
            class="btn-primary"
            icon="plus-solid"
            @click="createAccountServiceModal?.open()"
          >
            {{ $t("accounts.services.create.title") }}
          </ButtonWithIcon>
        </div>
      </ListEmptyState>
    </template>
    <template v-else>
      <AccountServicesTable
        :account-services="searchAccountServicesRequest.data.data"
        @deleted="searchAccountServicesRequest.fetch()"
        @updated="searchAccountServicesRequest.fetch()"
      />
      <CursorPagination
        :meta="searchAccountServicesRequest.data.meta"
        :total="searchAccountServicesRequest.data.total"
        @change-page="changePage"
      />
    </template>
  </div>
  <ModalDialog
    ref="createAccountServiceModal"
    v-slot="{ close }"
  >
    <AccountServicesCreateModal
      @close="close"
      @created="searchAccountServicesRequest.fetch()"
    />
  </ModalDialog>
</template>

<script lang="ts" setup>
import { useEventBus } from "@vueuse/core";
import DashboardHeader from "@/contexts/shared/ui/components/header/DashboardHeader.vue";
import BreadcrumbItem from "@/contexts/shared/ui/components/breadcrumbs/BreadcrumbItem.vue";
import BreadcrumbGroup from "@/contexts/shared/ui/components/breadcrumbs/BreadcrumbGroup.vue";
import useAccountServices from "@/contexts/accounts/composables/useAccountServices";
import useRequest, { type UseRequest } from "@/contexts/shared/composables/useRequest";
import type { SearchAccountServicesCriteria } from "@/contexts/accounts/models/SearchAccountServicesCriteria";
import useCriteria from "@/contexts/shared/composables/useCriteria";
import { emptyPagination, type Pagination } from "@/contexts/shared/models/Pagination";
import type { AccountService } from "@/contexts/accounts/models/AccountService";
import ModalDialog from "@/contexts/shared/ui/components/modal/ModalDialog.vue";
import AccountServicesCreateModal from "@/contexts/accounts/ui/modals/AccountServicesCreateModal.vue";
import DashboardHeaderActions from "@/contexts/shared/ui/components/header/DashboardHeaderActions.vue";
import ButtonWithIcon from "@/contexts/shared/ui/components/button/ButtonWithIcon.vue";
import LoadingSection from "@/contexts/shared/ui/components/loading/LoadingSection.vue";
import ListErrorState from "@/contexts/shared/ui/components/list/ListErrorState.vue";
import ListEmptyState from "@/contexts/shared/ui/components/list/ListEmptyState.vue";
import CursorPagination from "@/contexts/shared/ui/components/pagination/CursorPagination.vue";
import AccountServicesTable from "@/contexts/accounts/ui/components/AccountServicesTable.vue";

const { t: $t } = useI18n();
const router = useRouter();
const { searchAccountServices } = useAccountServices();
const accountBus = useEventBus<string>("account");

accountBus.on(() => router.push({ name: "settings" }));
useHead({ title: () => `${$t("settings.account.services.title")} - Hussbook` });

const { criteria, cursor, changePage } = useCriteria<SearchAccountServicesCriteria>({}, () => searchAccountServicesRequest.fetch());

const createAccountServiceModal = ref<InstanceType<typeof ModalDialog>>();

onMounted(() => {
  searchAccountServicesRequest.fetch();
});

const searchAccountServicesRequest: UseRequest<Pagination<AccountService>> = useRequest<Pagination<AccountService>>({
  initialLoading: true,
  value: emptyPagination<AccountService>(),
  promise: () => searchAccountServices(criteria.value, cursor.value),
  onSuccess: (response) => {
    if (cursor.value && response.data.length === 0) {
      changePage();
      return;
    }
  },
});
</script>
