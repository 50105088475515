<template>
  <ListDetails>
    <ListDetailsColumn>
      <ListDetailsCell label="bookings.props.code">
        {{ booking.code }}
      </ListDetailsCell>
      <ListDetailsCell label="bookings.props.status">
        <BookingsStatusBadge :status="booking.status" />
      </ListDetailsCell>
      <ListDetailsCell label="bookings.props.accommodation_id">
        <AccommodationsDetailsLink :accommodation="booking.accommodation" />
      </ListDetailsCell>
      <ListDetailsCell label="bookings.props.check_in">
        {{ booking.check_in.toLocaleDateString() }}
      </ListDetailsCell>
      <ListDetailsCell label="bookings.props.check_out">
        {{ booking.check_out.toLocaleDateString() }}
      </ListDetailsCell>
      <ListDetailsCell label="bookings.props.adults">
        {{ $t("bookings.manage.details.adults", booking.adults) }}
      </ListDetailsCell>
      <ListDetailsCell label="bookings.props.children">
        {{ $t("bookings.manage.details.children", booking.children) }}
      </ListDetailsCell>
    </ListDetailsColumn>
    <ListDetailsColumn>
      <ListDetailsCell label="bookings.props.provider">
        <BookingsProviderBadge :provider="booking.provider" />
      </ListDetailsCell>
      <ListDetailsCell label="bookings.props.channel">
        <BookingsChannelBadge :channel="booking.channel" />
      </ListDetailsCell>
      <ListDetailsCell label="bookings.props.language">
        {{ booking.language ? $t(`shared.language.${booking.language.toLowerCase()}`) : "-" }}
      </ListDetailsCell>
      <ListDetailsCell label="bookings.props.is_youngsters">
        <BookingsFeatureStatusBadge
          :has-feature="booking.is_youngsters"
          :padding="false"
        />
      </ListDetailsCell>
      <ListDetailsCell label="bookings.props.has_pets">
        <BookingsFeatureStatusBadge
          :has-feature="booking.has_pets"
          :padding="false"
        />
      </ListDetailsCell>
      <ListDetailsCell label="bookings.props.security_deposit">
        {{ (booking.security_deposit ?? 0) > 0 ? booking.security_deposit?.toFixed(2) : "-" }}
      </ListDetailsCell>
      <ListDetailsCell label="bookings.props.notes">
        {{ (booking.notes?.length ?? 0) > 0 ? booking.notes : "-" }}
      </ListDetailsCell>
    </ListDetailsColumn>
  </ListDetails>
</template>

<script lang="ts" setup>
import ListDetails from "@/contexts/shared/ui/components/list/ListDetails.vue";
import ListDetailsColumn from "@/contexts/shared/ui/components/list/ListDetailsColumn.vue";
import ListDetailsCell from "@/contexts/shared/ui/components/list/ListDetailsCell.vue";
import AccommodationsDetailsLink from "@/contexts/accommodations/ui/components/AccommodationsDetailsLink.vue";
import BookingsFeatureStatusBadge from "@/contexts/bookings/ui/components/BookingsFeatureStatusBadge.vue";
import type { Booking } from "@/contexts/bookings/models/Booking";
import BookingsStatusBadge from "@/contexts/bookings/ui/components/BookingsStatusBadge.vue";
import BookingsProviderBadge from "@/contexts/bookings/ui/components/BookingsProviderBadge.vue";
import BookingsChannelBadge from "@/contexts/bookings/ui/components/BookingsChannelBadge.vue";

defineProps<{
  booking: Booking;
}>();

const { t: $t } = useI18n();
</script>
