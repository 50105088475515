<template>
  {{ $t(`bookings.channels.${channel || "other"}`) }}
</template>

<script setup lang="ts">
import type { BookingChannel } from "@/contexts/bookings/models/Booking";

defineProps<{
  channel: BookingChannel | null;
}>();

const { t: $t } = useI18n();
</script>
