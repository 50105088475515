<template>
  <DashboardHeader>
    <DashboardBackTo :to="{ name: 'accommodations' }">
      {{ $t("accommodations.prices.back_to_list") }}
    </DashboardBackTo>
    <DashboardHeaderTitle>{{ $t("accommodations.prices.title") }}</DashboardHeaderTitle>
    <DashboardHeaderActions>
      <RouterLinkWithIcon
        :to="{ name: 'accommodations.prices.bulk' }"
        class="btn-primary"
        icon="grid-dividers-solid"
      >
        {{ $t("accommodations.prices.bulk.title") }}
      </RouterLinkWithIcon>
    </DashboardHeaderActions>
  </DashboardHeader>
  <div class="space-y-3">
    <FilterGroup
      :is-empty="criteriaEmpty"
      @clear="clearCriteria"
    >
      <FilterItem
        v-model="criteria.id[0]"
        required
        label="accommodations.prices.filters.id"
        title="accommodations.prices.filters_by.id"
      >
        <AccommodationsFilter />
      </FilterItem>
      <FilterItem
        v-model="criteria.date"
        required
        label="accommodations.prices.filters.date"
        title="accommodations.prices.filters_by.date"
      >
        <FilterDateRange />
      </FilterItem>
    </FilterGroup>
    <template v-if="searchAccommodationsPricesRequest.isLoading">
      <LoadingSection />
    </template>
    <template v-else-if="searchAccommodationsPricesRequest.hasErrors">
      <ListErrorState />
    </template>
    <template v-else-if="searchAccommodationsPricesRequest.data.length === 0">
      <ListEmptyState
        description="accommodations.prices.empty.description"
        icon="calendar-lines-pen-solid"
        title="accommodations.prices.empty.title"
      />
    </template>
    <template v-else>
      <AccommodationsPricesTable
        v-model="criteria"
        :prices="searchAccommodationsPricesRequest.data"
        @updated="fetchList"
      />
      <BookingAvailabilityLegend />
    </template>
  </div>
  <RouterView @fetch-list="fetchList" />
</template>

<script setup lang="ts">
import DashboardBackTo from "@/contexts/shared/ui/components/header/DashboardBackTo.vue";
import DashboardHeaderTitle from "@/contexts/shared/ui/components/header/DashboardHeaderTitle.vue";
import DashboardHeader from "@/contexts/shared/ui/components/header/DashboardHeader.vue";
import FilterGroup from "@/contexts/shared/ui/components/filter/FilterGroup.vue";
import { useEventBus } from "@vueuse/core";
import useCriteria from "@/contexts/shared/composables/useCriteria";
import useRequest, { type UseRequest } from "@/contexts/shared/composables/useRequest";
import useAccommodations from "@/contexts/accommodations/composables/useAccommodations";
import type { SearchAccommodationsPricesCriteria } from "@/contexts/accommodations/models/SearchAccommodationsPricesCriteria";
import useDayjs from "@/contexts/shared/composables/useDayjs";
import ListEmptyState from "@/contexts/shared/ui/components/list/ListEmptyState.vue";
import ListErrorState from "@/contexts/shared/ui/components/list/ListErrorState.vue";
import LoadingSection from "@/contexts/shared/ui/components/loading/LoadingSection.vue";
import FilterDateRange from "@/contexts/shared/ui/components/filter/FilterDateRange.vue";
import FilterItem from "@/contexts/shared/ui/components/filter/FilterItem.vue";
import AccommodationsFilter from "@/contexts/accommodations/ui/components/AccommodationsFilter.vue";
import type { AccommodationPrices } from "@/contexts/accommodations/models/AccommodationPrices";
import AccommodationsPricesTable from "@/contexts/accommodations/ui/components/AccommodationsPricesTable.vue";
import BookingAvailabilityLegend from "@/contexts/bookings/ui/components/BookingAvailabilityLegend.vue";
import DashboardHeaderActions from "@/contexts/shared/ui/components/header/DashboardHeaderActions.vue";
import RouterLinkWithIcon from "@/contexts/shared/ui/components/button/RouterLinkWithIcon.vue";
import { isEmpty } from "lodash-es";

const { t: $t } = useI18n();
const dayjs = useDayjs();
const router = useRouter();
const accountBus = useEventBus<string>("account");
const { searchAccommodationsPrices } = useAccommodations();

accountBus.on(() => router.push({ name: "accommodations" }));
useHead({ title: () => `${$t("accommodations.prices.title")} - Hussbook` });

const { criteria, criteriaEmpty, clearCriteria } = useCriteria<SearchAccommodationsPricesCriteria>({ id: [], date: { from: dayjs().toDate(), to: dayjs().add(30, "days").toDate() } }, () => searchAccommodationsPricesRequest.fetch(), ["id"]);

const searchAccommodationsPricesRequest: UseRequest<AccommodationPrices[]> = useRequest<AccommodationPrices[]>({
  value: [],
  promise: () => searchAccommodationsPrices(criteria.value),
});

const fetchList = () => {
  if (isEmpty(criteria.value.id) || !criteria.value.date) {
    return;
  }
  searchAccommodationsPricesRequest.fetch();
};
</script>
