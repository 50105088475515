<template>
  <DropdownMenu>
    <template #button>
      <slot />
    </template>
    <DropdownMenuItemButton @click="ownerBankAccountsUpdateModal?.open()">
      {{ $t("owner_bank_accounts.actions.edit") }}
    </DropdownMenuItemButton>
    <DropdownMenuItemButton
      :disabled="isDefault"
      @click="ownerBankAccountsDefaultModal?.open()"
    >
      {{ $t("owner_bank_accounts.actions.default") }}
    </DropdownMenuItemButton>
    <DropdownMenuItemButton
      :disabled="isDefault"
      class="text-red-600 hover:text-red-700"
      @click="ownerBankAccountsDeleteModal?.open()"
    >
      {{ $t("owner_bank_accounts.actions.delete") }}
    </DropdownMenuItemButton>
  </DropdownMenu>
  <ModalDialog
    ref="ownerBankAccountsUpdateModal"
    v-slot="{ close }"
    size="2xl"
  >
    <OwnerBankAccountsUpdateModal
      :bank-account="bankAccount"
      @close="close"
      @updated="$emit('updated')"
    />
  </ModalDialog>
  <ModalDialog
    ref="ownerBankAccountsDefaultModal"
    v-slot="{ close }"
  >
    <OwnerBankAccountsDefaultModal
      :bank-account="bankAccount"
      @close="close"
      @selected-default="$emit('selectedDefault')"
    />
  </ModalDialog>
  <ModalDialog
    ref="ownerBankAccountsDeleteModal"
    v-slot="{ close }"
  >
    <OwnerBankAccountsDeleteModal
      :bank-account="bankAccount"
      @close="close"
      @deleted="$emit('deleted')"
    />
  </ModalDialog>
</template>

<script setup lang="ts">
import type { OwnerBankAccount } from "@/contexts/owner-bank-accounts/models/OwnerBankAccount";
import DropdownMenu from "@/contexts/shared/ui/components/dropdown/DropdownMenu.vue";
import DropdownMenuItemButton from "@/contexts/shared/ui/components/dropdown/DropdownMenuItemButton.vue";
import ModalDialog from "@/contexts/shared/ui/components/modal/ModalDialog.vue";
import OwnerBankAccountsDefaultModal from "@/contexts/owner-bank-accounts/ui/modals/OwnerBankAccountsDefaultModal.vue";
import OwnerBankAccountsDeleteModal from "@/contexts/owner-bank-accounts/ui/modals/OwnerBankAccountsDeleteModal.vue";
import OwnerBankAccountsUpdateModal from "@/contexts/owner-bank-accounts/ui/modals/OwnerBankAccountsUpdateModal.vue";

defineProps<{
  bankAccount: OwnerBankAccount;
  isDefault: boolean;
}>();

defineEmits<{
  updated: [];
  deleted: [];
  selectedDefault: [];
}>();

const { t: $t } = useI18n();

const ownerBankAccountsUpdateModal = ref<InstanceType<typeof ModalDialog>>();
const ownerBankAccountsDeleteModal = ref<InstanceType<typeof ModalDialog>>();
const ownerBankAccountsDefaultModal = ref<InstanceType<typeof ModalDialog>>();
</script>
