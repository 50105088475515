<template>
  <div class="flex items-center justify-between rounded-md border border-gray-200 p-4">
    <div class="flex items-center gap-4">
      <div class="size-6">
        <ProviderIcon :icon="provider as ProviderIconTypes" />
      </div>
      <div class="text-gray-800">
        {{ $t(`shared.providers.${snakeCase(provider)}`) }}
      </div>
    </div>
    <div class="flex items-center gap-5">
      <template v-if="!!integration">
        <ButtonWithIcon
          class="btn-white"
          icon="pen-solid"
          @click="accountIntegrationsCredentialsModalDialog?.open()"
        >
          {{ $t("accounts.integrations.update.title") }}
        </ButtonWithIcon>
      </template>
      <FormSwitch
        :model-value="integration?.enabled ?? false"
        :name="provider"
        @update:model-value="toggleIntegration"
      />
    </div>
  </div>
  <ModalDialog
    ref="accountIntegrationsCredentialsModalDialog"
    v-slot="{ close }"
    size="large"
  >
    <AccountIntegrationsCredentialsModal
      :credentials="credentials"
      :errors="createAccountIntegrationRequest.errors || updateAccountIntegrationRequest.errors"
      :integration="integration"
      :is-loading="createAccountIntegrationRequest.isLoading || updateAccountIntegrationRequest.isLoading"
      :provider="provider"
      @close="close"
      @submit="submit"
    />
  </ModalDialog>
</template>

<script lang="ts" setup>
import { snakeCase } from "lodash-es";
import FormSwitch from "@/contexts/shared/ui/components/form/FormSwitch.vue";
import ProviderIcon, { type ProviderIconTypes } from "@/contexts/shared/ui/components/icon/ProviderIcon.vue";
import type { AccountIntegration, AccountIntegrationProvider, UnsavedAccountIntegration } from "@/contexts/accounts/models/AccountIntegration";
import ModalDialog from "@/contexts/shared/ui/components/modal/ModalDialog.vue";
import AccountIntegrationsCredentialsModal from "@/contexts/accounts/ui/modals/AccountIntegrationsCredentialsModal.vue";
import useRequest from "@/contexts/shared/composables/useRequest";
import useNotification from "@/contexts/shared/composables/useNotification";
import useAccountIntegrations from "@/contexts/accounts/composables/useAccountIntegrations";
import ButtonWithIcon from "@/contexts/shared/ui/components/button/ButtonWithIcon.vue";

const props = defineProps<{
  provider: AccountIntegrationProvider;
  credentials: string[];
  integration: AccountIntegration | undefined;
}>();

const emit = defineEmits<{
  created: [value: AccountIntegration];
  updated: [value: AccountIntegration];
  enabled: [];
  disabled: [];
}>();

const { t: $t } = useI18n();
const { successNotification, errorNotification } = useNotification();
const { createAccountIntegration, updateAccountIntegration, disableAccountIntegration, enableAccountIntegration } = useAccountIntegrations();

const accountIntegrationsCredentialsModalDialog = ref<InstanceType<typeof ModalDialog>>();

const toggleIntegration = () => {
  if (!props.integration) {
    accountIntegrationsCredentialsModalDialog.value?.open();
    return;
  }
  if (!props.integration.enabled) {
    enableAccountIntegrationRequest.fetch();
    return;
  }
  disableAccountIntegrationRequest.fetch();
};

const submit = (value: UnsavedAccountIntegration) => {
  if (!props.integration) {
    createAccountIntegrationRequest.fetch(value);
    return;
  }
  updateAccountIntegrationRequest.fetch(value);
};

const createAccountIntegrationRequest = useRequest({
  promise: (value: UnsavedAccountIntegration) => createAccountIntegration(value),
  onSuccess: (response) => {
    successNotification("accounts.integrations.manage.success");
    accountIntegrationsCredentialsModalDialog.value?.close();
    emit("created", response);
  },
  onFailure: () => {
    errorNotification("accounts.integrations.manage.error");
  },
});
const updateAccountIntegrationRequest = useRequest({
  promise: (value: UnsavedAccountIntegration) => updateAccountIntegration(String(props.integration?.id), value),
  onSuccess: (response) => {
    successNotification("accounts.integrations.manage.success");
    accountIntegrationsCredentialsModalDialog.value?.close();
    emit("updated", response);
  },
  onFailure: () => {
    errorNotification("accounts.integrations.manage.error");
  },
});
const enableAccountIntegrationRequest = useRequest({
  promise: () => enableAccountIntegration(String(props.integration?.id)),
  onSuccess: () => {
    successNotification("accounts.integrations.manage.success");
    emit("enabled");
  },
  onFailure: () => {
    errorNotification("accounts.integrations.manage.error");
  },
});
const disableAccountIntegrationRequest = useRequest({
  promise: () => disableAccountIntegration(String(props.integration?.id)),
  onSuccess: () => {
    successNotification("accounts.integrations.manage.success");
    emit("disabled");
  },
  onFailure: () => {
    errorNotification("accounts.integrations.manage.error");
  },
});
</script>
