<template>
  <PopoverRoot class="relative">
    <PopoverButton
      :id="id"
      :class="classes"
      as="button"
      class="flex w-full cursor-default items-center justify-between rounded border bg-white py-1 pl-2 text-left text-sm shadow-sm focus:outline-none focus:ring-1 focus:ring-primary"
    >
      {{ selectedValueLabel }}
    </PopoverButton>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
      @after-leave="onClose"
    >
      <PopoverPanel
        v-slot="{ close }"
        class="absolute z-10 w-full"
        focus
      >
        <div
          :class="{ 'w-full lg:w-2/3': !customWidth }"
          class="left-0 pt-1"
        >
          <div class="h-full w-full space-y-2.5 rounded border border-gray-200 bg-white py-2 shadow">
            <div class="flex px-3">
              <FormText
                id="search"
                :model-value="search"
                :placeholder="$t('shared.search')"
                name="form_search"
                @update:model-value="updateSearch"
                @keydown.enter.prevent="selectFirstOrCreate(close)"
              />
            </div>
            <div>
              <div v-if="pagination.data.length === 0 && !withCreate">
                <div class="flex w-full items-center space-x-2 px-3.5 py-2 text-left text-sm leading-4 text-gray-800">
                  {{ $t("shared.empty") }}
                </div>
              </div>
              <div
                v-if="pagination.data.length > 0"
                class="max-h-80 overflow-y-auto"
              >
                <template
                  v-for="item in pagination.data"
                  :key="item.id"
                >
                  <button
                    :aria-selected="item[index as keyof T] === modelValue"
                    class="group flex w-full items-center justify-between space-x-2 truncate px-3.5 py-2 text-left text-sm leading-4 text-gray-800 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none aria-selected:bg-gray-100"
                    type="button"
                    @click="toggleItem(item, close)"
                  >
                    <span class="truncate">{{ item[label as keyof T] }}</span>
                    <FontAwesomeIcon
                      class="hidden h-3.5 w-3.5 flex-none fill-primary group-aria-selected:block"
                      icon="circle-check-solid"
                    />
                  </button>
                </template>
              </div>
              <p
                v-if="pagination.data.length > 10"
                class="las px-3.5 py-2.5 text-xs text-gray-500 last:pb-0"
              >
                {{ $t("shared.pagination.total") }} <span class="font-medium">{{ pagination.total }}</span>
                {{ $t("shared.pagination.items", pagination.total) }}
              </p>
              <slot
                name="footer"
                v-bind="{ close }"
              />
            </div>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </PopoverRoot>
</template>

<script generic="T" lang="ts" setup>
import FormText from "@/contexts/shared/ui/components/form/FormText.vue";
import { Popover as PopoverRoot, PopoverButton, PopoverPanel } from "@headlessui/vue";
import FontAwesomeIcon from "@/contexts/shared/ui/components/icon/FontAwesomeIcon.vue";
import type { Pagination } from "@/contexts/shared/models/Pagination";
import type { Ref } from "vue";

const props = defineProps<{
  modelValue: string | null;
  index: string;
  label: string;
  search: string | undefined;
  pagination: Pagination<T>;
  withCreate?: boolean;
  customWidth?: boolean;
}>();

const emit = defineEmits<{
  "update:modelValue": [value: string | null];
  "update:search": [value: string];
  fetch: [];
  "fetch:item": [value: string, onSelected: (item: T) => void];
  create: [];
}>();

const { t: $t } = useI18n();

const classes = computed(() => ({
  "border-gray-300 focus:border-primary focus:outline-none focus:ring-1 focus:ring-indigo-500 focus-within:border-primary focus-within:outline-none focus-within:ring-1 focus-within:ring-indigo-500": !hasErrors.value,
  "border-red-500 focus:border-red-500 focus:outline-none focus:ring-1 focus:ring-red-500 focus-within:border-red-500 focus-within:outline-none focus-within:ring-1 focus-within:ring-red-500 text-red-900": hasErrors.value,
}));

onMounted(() => {
  if (props.modelValue !== null) {
    emit("fetch:item", props.modelValue, (item: T) => (selectedValue.value = item));
  }
  emit("fetch");
});
watch(
  () => props.modelValue,
  (value) => {
    if (value !== null && (!selectedValue.value?.[props.index as keyof T] || selectedValue.value?.[props.index as keyof T] !== value)) {
      emit("fetch:item", value, (item: T) => (selectedValue.value = item));
    }
  },
);

const id = inject<string>("id", "");
const errors = inject<Ref<string[] | undefined> | undefined>("errors", undefined);
const hasErrors = computed<boolean>(() => (errors?.value?.length ?? 0) > 0);
const selectedValue = ref<T>();
const selectedValueLabel = computed(() => (selectedValue.value ? selectedValue.value?.[props.label as keyof T] : "-"));

const updateSearch = (value: string | number | null | undefined) => {
  emit("update:search", String(value ?? ""));
  emit("fetch");
};
const onClose = () => {
  if (props.search) {
    emit("update:search", "");
    emit("fetch");
  }
};
const toggleItem = (item: T, close: () => void) => {
  if (selectedValue.value?.[props.index as keyof T] === item?.[props.index as keyof T]) {
    selectedValue.value = undefined;
    emit("update:modelValue", null);
    close();
    return;
  }
  selectedValue.value = item;
  emit("update:modelValue", (item?.[props.index as keyof T] as string) ?? null);
  close();
};
const selectFirstOrCreate = (close: () => void) => {
  if (props.search && props.pagination.data.length > 0) {
    toggleItem(props.pagination.data[0], close);
    return;
  }
  if (props.search && props.withCreate) {
    emit("create");
    return;
  }
};
</script>
