<template>
  <PopoverRoot class="relative">
    <PopoverButton
      :id="id"
      :class="classes"
      :disabled="disabled ?? false"
      as="button"
      class="flex w-full cursor-default items-center justify-between rounded border py-1 pl-2 text-left text-sm shadow-sm focus:outline-none focus:ring-1 focus:ring-primary"
    >
      {{ label }}
    </PopoverButton>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <PopoverPanel
        v-slot="{ close }"
        class="absolute z-20"
      >
        <div class="left-0 pt-1">
          <div class="flex h-full w-full flex-col rounded border border-gray-200 bg-white shadow">
            <DatePicker
              v-model="input"
              :mode="mode"
              :first-day-of-week="2"
              :locale="locale"
              :max-date="maxDate"
              :min-date="minDate"
              hide-time-header
              borderless
              :rules="rules"
              is-required
              is24hr
              transparent
              @update:model-value="mode !== 'dateTime' ? close() : void 0"
            />
            <template v-if="mode === 'dateTime'">
              <div class="px-3 py-2">
                <button
                  class="btn-white w-full"
                  type="button"
                  @click="close"
                >
                  <span class="mx-auto">{{ $t("shared.apply") }}</span>
                </button>
              </div>
            </template>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </PopoverRoot>
</template>

<script lang="ts" setup>
import type { Ref } from "vue";
import { Popover as PopoverRoot, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { DatePicker } from "v-calendar";
import "v-calendar/style.css";

export interface TimeParts {
  hours: number;
  minutes: number;
  seconds: number;
  milliseconds: number;
}

interface NumberRuleConfig {
  min?: number;
  max?: number;
  interval?: number;
}

type DatePartsRuleFunction = (part: number, parts: TimeParts) => boolean;
type DatePartsRule = number | Array<number> | NumberRuleConfig | DatePartsRuleFunction;
type DatePartsRules = {
  hours?: DatePartsRule;
  minutes?: DatePartsRule;
  seconds?: DatePartsRule;
  milliseconds?: DatePartsRule;
};

const props = withDefaults(
  defineProps<{
    modelValue: Date | null;
    minDate?: Date | null | undefined;
    maxDate?: Date | null | undefined;
    disabled?: boolean;
    mode?: "date" | "dateTime" | "time";
    rules?: DatePartsRules;
  }>(),
  {
    minDate: undefined,
    maxDate: undefined,
    mode: "date",
    rules: () => ({}),
  },
);

const emit = defineEmits<{
  "update:modelValue": [value: Date | null];
}>();

const { t: $t, locale } = useI18n();

const input = computed<Date | null>({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});
const label = computed<string>(() => {
  if (!input.value) {
    return "-";
  }
  if (props.mode === "time") {
    return input.value.toLocaleTimeString();
  }
  if (props.mode === "dateTime") {
    return input.value.toLocaleString(undefined, { dateStyle: "short", timeStyle: "short" });
  }
  return input.value.toLocaleDateString();
});

const id = inject<string>("id", "");
const errors = inject<Ref<string[] | undefined> | undefined>("errors", undefined);
const hasErrors = computed<boolean>(() => (errors?.value?.length ?? 0) > 0);

const classes = computed(() => ({
  "bg-white": !props.disabled,
  "bg-gray-50": props.disabled,
  "border-gray-300 focus:border-primary focus:outline-none focus:ring-1 focus:ring-indigo-500 focus-within:border-primary focus-within:outline-none focus-within:ring-1 focus-within:ring-indigo-500": !hasErrors.value,
  "border-red-500 focus:border-red-500 focus:outline-none focus:ring-1 focus:ring-red-500 focus-within:border-red-500 focus-within:outline-none focus-within:ring-1 focus-within:ring-red-500 text-red-900": hasErrors.value,
}));
</script>
