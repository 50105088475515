<template>
  <div class="relative rounded shadow-sm">
    <input
      :id="id"
      :class="classes"
      :data-testid="id"
      :name="name"
      :placeholder="placeholder"
      :type="type"
      :value="modelValue"
      autocomplete="password"
      @input="$emit('update:modelValue', ($event.target as HTMLInputElement).value)"
    />
    <div class="absolute inset-y-0 right-0 flex items-center space-x-1.5 pr-1.5">
      <button
        class="h-5 rounded px-1 focus:outline-none focus:ring-2 focus:ring-primary"
        type="button"
        @click="toggle"
      >
        <FontAwesomeIcon
          v-if="type === 'password'"
          class="h-3.5 w-3.5 fill-gray-500"
          icon="eye-solid"
        />
        <FontAwesomeIcon
          v-else
          class="h-3.5 w-3.5 fill-gray-500"
          icon="eye-slash-solid"
        />
      </button>
      <button
        class="h-5 rounded px-1 focus:outline-none focus:ring-2 focus:ring-primary"
        title="Generate password"
        type="button"
        @click="generate"
      >
        <FontAwesomeIcon
          class="h-3.5 w-3.5 fill-gray-500"
          icon="repeat-solid"
        />
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Ref } from "vue";
import FontAwesomeIcon from "@/contexts/shared/ui/components/icon/FontAwesomeIcon.vue";

withDefaults(
  defineProps<{
    name: string;
    modelValue: string | number | null | undefined;
    placeholder?: string;
  }>(),
  {
    placeholder: undefined,
  },
);

const emit = defineEmits<{
  "update:modelValue": [value: string | number | null | undefined];
  generated: [value: string];
}>();

const type = ref<"text" | "password">("password");
const id = inject<string>("id", "");
const errors = inject<Ref<string[] | undefined> | undefined>("errors", undefined);
const hasErrors = computed<boolean>(() => (errors?.value?.length ?? 0) > 0);

const classes = computed(() => ({
  "block w-full px-2 py-1 pr-16 text-sm rounded": true,
  "border-gray-300 focus:border-primary focus:ring-primary": !hasErrors.value,
  "border-red-500 focus:border-red-500 focus:ring-red-500 text-red-900 placeholder-red-300": hasErrors.value,
}));

const toggle = () => (type.value = type.value === "password" ? "text" : "password");
const generate = () => {
  const chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const passwordLength = 12;
  let password = "";
  for (let i = 0; i <= passwordLength; i++) {
    const randomNumber = Math.floor(Math.random() * chars.length);
    password += chars.substring(randomNumber, randomNumber + 1);
  }
  emit("update:modelValue", password);
  emit("generated", password);
};
</script>
