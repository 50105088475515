<template>
  <div v-if="(extras?.length ?? 0) > 0">
    <ListTable>
      <ListTableHeader>
        <ListTableHeaderCell primary>{{ $t("accommodations.extras.props.name") }}</ListTableHeaderCell>
        <ListTableHeaderCell optional>{{ $t("accommodations.extras.props.mode") }}</ListTableHeaderCell>
        <ListTableHeaderCell>{{ $t("accommodations.extras.props.type") }}</ListTableHeaderCell>
        <ListTableHeaderCell>{{ $t("accommodations.extras.props.payment_time") }}</ListTableHeaderCell>
        <ListTableHeaderCell>{{ $t("accommodations.extras.props.price") }}</ListTableHeaderCell>
        <ListTableHeaderCell optional>{{ $t("accommodations.extras.props.conditions") }}</ListTableHeaderCell>
        <ListTableHeaderCell optional>{{ $t("accommodations.extras.props.maximum_units") }}</ListTableHeaderCell>
        <ListTableHeaderCell optional>{{ $t("accommodations.extras.props.updated_at") }}</ListTableHeaderCell>
      </ListTableHeader>
      <ListTableBody>
        <template
          v-for="extra in extras"
          :key="extra.id"
        >
          <ListTableBodyRow>
            <ListTableBodyCell>{{ $t(`accommodations.extras.name.${extra.name}`) }}</ListTableBodyCell>
            <ListTableBodyCell optional>{{ $t(`accommodations.extras.mode.${extra.mode}`) }}</ListTableBodyCell>
            <ListTableBodyCell>{{ $t(`accommodations.extras.type.${extra.type}`) }}</ListTableBodyCell>
            <ListTableBodyCell>{{ $t(`accommodations.extras.payment_time.${extra.payment_time}`) }}</ListTableBodyCell>
            <ListTableBodyCell>{{ `${Number(extra.price.price / 100).toFixed(2)} ${extra.price.type === "fixed" ? "€" : "%"}` }}</ListTableBodyCell>
            <ListTableBodyCell optional>{{ $t(isEmpty(extra.conditions.number_of_nights) && isEmpty(extra.conditions.applicable_period) ? "accommodations.extras.without_conditions" : "accommodations.extras.with_conditions") }}</ListTableBodyCell>
            <ListTableBodyCell optional>{{ extra.maximum_units || "-" }}</ListTableBodyCell>
            <ListTableBodyCell optional>{{ extra.updated_at.toLocaleString() }}</ListTableBodyCell>
          </ListTableBodyRow>
        </template>
      </ListTableBody>
    </ListTable>
  </div>
  <SectionEmptyState v-else>
    {{ $t("accommodations.manage.extras.empty") }}
  </SectionEmptyState>
</template>

<script setup lang="ts">
import type { AccommodationExtra } from "@/contexts/accommodations/models/AccommodationExtra";
import SectionEmptyState from "@/contexts/shared/ui/components/section/SectionEmptyState.vue";
import ListTableHeaderCell from "@/contexts/shared/ui/components/list/ListTableHeaderCell.vue";
import ListTableBodyCell from "@/contexts/shared/ui/components/list/ListTableBodyCell.vue";
import ListTable from "@/contexts/shared/ui/components/list/ListTable.vue";
import ListTableHeader from "@/contexts/shared/ui/components/list/ListTableHeader.vue";
import ListTableBody from "@/contexts/shared/ui/components/list/ListTableBody.vue";
import ListTableBodyRow from "@/contexts/shared/ui/components/list/ListTableBodyRow.vue";
import { isEmpty } from "lodash-es";

defineProps<{
  extras: AccommodationExtra[] | undefined;
}>();

const { t: $t } = useI18n();
</script>
