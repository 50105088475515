<template>
  <div class="flex min-h-screen items-center justify-center bg-gray-100">
    <div class="mx-5 my-10 w-full max-w-full sm:mx-10 md:w-[34rem]">
      <a
        class="mb-6 flex items-center justify-start px-6"
        href="https://www.hussbook.com"
      >
        <BrandIcon
          class="h-12 w-auto"
          icon="icon"
        />
        <div class="ml-4 text-xl font-semibold">Hussbook</div>
      </a>
      <slot />
      <div class="my-6 px-3">
        <div class="flex items-center text-sm text-gray-600">
          <a
            class="text-gray-600 hover:text-gray-800"
            href="https://www.hussbook.com"
            target="_blank"
          >
            © {{ year }} Hussbook
          </a>
          <div class="mx-2.5 hidden size-1 rotate-0 rounded-full bg-gray-500 md:block" />
          <a
            class="hidden text-gray-600 hover:text-gray-800 md:block"
            href="https://www.hussbook.com/contact"
            target="_blank"
          >
            {{ $t("auth.footer.contact") }}
          </a>
          <div class="mx-2.5 hidden size-1 rotate-0 rounded-full bg-gray-500 md:block" />
          <a
            class="hidden text-gray-600 hover:text-gray-800 md:block"
            href="https://www.hussbook.com/privacy-policy"
            target="_blank"
          >
            {{ $t("auth.footer.privacy_terms") }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import BrandIcon from "@/contexts/shared/ui/components/icon/BrandIcon.vue";

const { t: $t } = useI18n();

const year = computed(() => new Date().getFullYear());
</script>
