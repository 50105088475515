<template>
  {{ $t(`booking_imports.type.${type}`) }}
</template>

<script setup lang="ts">
import type { BookingImportType } from "@/contexts/booking-imports/models/BookingImport";

defineProps<{
  type: BookingImportType;
}>();

const { t: $t } = useI18n();
</script>
