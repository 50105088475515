<template>
  <select
    :id="id"
    :class="classes"
    :name="name"
    :value="modelValue"
    :disabled="disabled"
    @input="$emit('update:modelValue', ($event.target as HTMLSelectElement).value)"
  >
    <option
      :disabled="!nullable"
      :value="null"
    >
      {{ $t("shared.form.select.any") }}
    </option>
    <option
      v-for="option in options"
      :key="String(option.value)"
      :value="option.value"
      :disabled="option.disabled"
    >
      {{ option.label }}
    </option>
  </select>
</template>

<script lang="ts" setup>
import type { Option } from "@/contexts/shared/models/Select";
import type { Ref } from "vue";

const props = withDefaults(
  defineProps<{
    name: string;
    modelValue: string | number | null;
    options: Option[];
    padding?: boolean;
    nullable?: boolean;
    disabled?: boolean;
  }>(),
  {
    padding: true,
  },
);

defineEmits<{
  "update:modelValue": [value: string | number | null];
}>();

const { t: $t } = useI18n();

const id = inject<string>("id", "");
const errors = inject<Ref<string[] | undefined> | undefined>("errors", undefined);
const hasErrors = computed<boolean>(() => (errors?.value?.length ?? 0) > 0);

const classes = computed(() => ({
  "block w-full rounded shadow-sm text-sm disabled:cursor-default disabled:bg-gray-50": true,
  "px-2 py-1": props.padding,
  "border-gray-300 focus:border-primary focus:ring-primary": !hasErrors.value,
  "border-red-500 focus:border-red-500 focus:ring-red-500 text-red-900 placeholder-red-300": hasErrors.value,
}));
</script>
