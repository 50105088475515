<template>
  <DropdownMenu>
    <template #button>
      <slot />
    </template>
    <DropdownMenuItemButton
      v-if="!hideEdit"
      @click="updateOwnerModal?.open()"
    >
      {{ $t("owners.actions.edit") }}
    </DropdownMenuItemButton>
    <DropdownMenuItemButton
      class="text-red-600 hover:text-red-700"
      @click="deleteOwnerModal?.open()"
    >
      {{ $t("owners.delete.title") }}
    </DropdownMenuItemButton>
  </DropdownMenu>
  <ModalDialog
    ref="updateOwnerModal"
    v-slot="{ close }"
  >
    <OwnersUpdateModal
      :owner="owner"
      @close="close"
      @updated="$emit('updated')"
    />
  </ModalDialog>
  <ModalDialog
    ref="deleteOwnerModal"
    v-slot="{ close }"
  >
    <OwnersDeleteModal
      :owner="owner"
      @close="close"
      @deleted="$emit('deleted')"
    />
  </ModalDialog>
</template>

<script lang="ts" setup>
import type { Owner } from "@/contexts/owners/models/Owner";
import DropdownMenu from "@/contexts/shared/ui/components/dropdown/DropdownMenu.vue";
import ModalDialog from "@/contexts/shared/ui/components/modal/ModalDialog.vue";
import DropdownMenuItemButton from "@/contexts/shared/ui/components/dropdown/DropdownMenuItemButton.vue";
import OwnersUpdateModal from "@/contexts/owners/ui/modals/OwnersUpdateModal.vue";
import OwnersDeleteModal from "@/contexts/owners/ui/modals/OwnersDeleteModal.vue";

defineProps<{
  owner: Owner;
  hideEdit?: boolean;
}>();

defineEmits<{
  updated: [];
  deleted: [];
}>();

const { t: $t } = useI18n();

const updateOwnerModal = ref<InstanceType<typeof ModalDialog>>();
const deleteOwnerModal = ref<InstanceType<typeof ModalDialog>>();
</script>
