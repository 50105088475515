<template>
  <template v-if="hasFeature">
    <PillBadge
      :padding="padding"
      class="pill-badge-green"
    >
      <FontAwesomeIcon
        class="mr-2 h-4 w-4 fill-green-500"
        icon="circle-check-solid"
      />
      {{ $t("bookings.feature.true") }}
    </PillBadge>
  </template>
  <template v-else>
    <PillBadge
      :padding="padding"
      class="pill-badge-red"
    >
      <FontAwesomeIcon
        class="mr-2 h-4 w-4 fill-red-500"
        icon="circle-xmark-solid"
      />
      {{ $t("bookings.feature.false") }}
    </PillBadge>
  </template>
</template>

<script lang="ts" setup>
import PillBadge from "@/contexts/shared/ui/components/badge/PillBadge.vue";
import FontAwesomeIcon from "@/contexts/shared/ui/components/icon/FontAwesomeIcon.vue";

withDefaults(
  defineProps<{
    hasFeature: boolean;
    padding?: boolean;
  }>(),
  {
    padding: true,
  },
);

const { t: $t } = useI18n();
</script>
