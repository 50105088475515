<template>
  <Badge :class="classes">
    {{ $t(`bookings.payments.status.${status}`) }}
  </Badge>
</template>

<script setup lang="ts">
import Badge from "@/contexts/shared/ui/components/badge/Badge.vue";
import type { BookingPaymentStatus } from "@/contexts/bookings/models/BookingPayment";

const props = defineProps<{
  status: BookingPaymentStatus | null;
}>();

const { t: $t } = useI18n();

const classes = computed(() => {
  switch (props.status) {
    case "pending":
      return "badge-gray";
    case "succeeded":
      return "badge-green";
    case "cancelled":
    case "failed":
      return "badge-orange";
  }
});
</script>
