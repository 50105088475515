<template>
  <FormAsyncSelect
    v-model:search="criteria.search"
    :model-value="modelValue"
    :pagination="searchOwnersRequest.data"
    index="id"
    label="name"
    with-create
    @create="showCreateOwnersModal()"
    @fetch="searchOwnersRequest.fetch()"
    @update:modelValue="$emit('update:modelValue', $event)"
    @fetch:item="showSelectedOwner"
  >
    <template #footer>
      <div>
        <button
          class="flex w-full items-center space-x-2 px-3.5 py-2 text-left text-sm leading-4 text-gray-800 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none"
          type="button"
          @click="showCreateOwnersModal()"
        >
          <FontAwesomeIcon
            class="h-3.5 w-3.5 flex-none fill-gray-700"
            icon="plus-solid"
          />
          <span class="truncate">{{ $t("owners.create.title") }}</span>
        </button>
      </div>
    </template>
  </FormAsyncSelect>
  <ModalDialog
    ref="createOwnerModal"
    v-slot="{ close }"
  >
    <OwnersCreateModal
      @close="close"
      @created="onCreatedOwner"
    />
  </ModalDialog>
</template>

<script lang="ts" setup>
import FormAsyncSelect from "@/contexts/shared/ui/components/form/FormAsyncSelect.vue";
import useOwners from "@/contexts/owners/composables/useOwners";
import useRequest from "@/contexts/shared/composables/useRequest";
import { emptyPagination, type Pagination } from "@/contexts/shared/models/Pagination";
import type { Owner } from "@/contexts/owners/models/Owner";
import type { SearchOwnersCriteria } from "@/contexts/owners/models/SearchOwnersCriteria";
import ModalDialog from "@/contexts/shared/ui/components/modal/ModalDialog.vue";
import OwnersCreateModal from "@/contexts/owners/ui/modals/OwnersCreateModal.vue";
import FontAwesomeIcon from "@/contexts/shared/ui/components/icon/FontAwesomeIcon.vue";

defineProps<{
  modelValue: string | null;
}>();

const emit = defineEmits<{
  "update:modelValue": [value: string | null];
}>();

const { t: $t } = useI18n();
const { searchOwners, showOwner } = useOwners();

const criteria = ref<SearchOwnersCriteria>({ search: "" });

const createOwnerModal = ref<InstanceType<typeof ModalDialog>>();

const searchOwnersRequest = useRequest<Pagination<Owner>>({
  initialLoading: true,
  value: emptyPagination<Owner>(),
  promise: () => searchOwners(criteria.value),
});
const showSelectedOwner = (id: string, onSelected: (item: Owner) => void) => showOwner(id).then((response) => onSelected(response));
const showCreateOwnersModal = () => createOwnerModal.value?.open();
const onCreatedOwner = (owner: Owner) => {
  emit("update:modelValue", owner.id);
  searchOwnersRequest.fetch();
};
</script>
