import type { Option } from "@/contexts/shared/models/Select";
import LanguageProvider from "@/contexts/shared/providers/languageProvider";

export default function useLanguage() {
  const { t: $t } = useI18n();

  const languageOptions: Option[] = LanguageProvider.get().map((item) => ({ value: item, label: $t(`shared.language.${item}`) }));

  return {
    languageOptions,
  };
}
