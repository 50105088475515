<template>
  <FormAsyncSelect
    v-model:search="criteria.search"
    :model-value="modelValue"
    :pagination="searchBookingGuestsRequest.data"
    index="id"
    label="name"
    with-create
    @create="showCreateBookingGuestModal()"
    @fetch="searchBookingGuestsRequest.fetch()"
    @update:modelValue="$emit('update:modelValue', $event)"
    @fetch:item="showSelectedBookingGuest"
  >
    <template #footer>
      <div>
        <button
          class="flex w-full items-center space-x-2 px-3.5 py-2 text-left text-sm leading-4 text-gray-800 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none"
          type="button"
          @click="showCreateBookingGuestModal()"
        >
          <FontAwesomeIcon
            class="h-3.5 w-3.5 flex-none fill-gray-700"
            icon="plus-solid"
          />
          <span class="truncate">{{ $t("booking_guests.create.title") }}</span>
        </button>
      </div>
    </template>
  </FormAsyncSelect>
  <ModalDialog
    ref="createBookingGuestModal"
    v-slot="{ close }"
  >
    <BookingGuestsCreateModal
      @close="close"
      @created="onCreatedBookingGuest"
    />
  </ModalDialog>
</template>

<script lang="ts" setup>
import useRequest from "@/contexts/shared/composables/useRequest";
import type { Pagination } from "@/contexts/shared/models/Pagination";
import { emptyPagination } from "@/contexts/shared/models/Pagination";
import useBookingGuests from "@/contexts/booking-guests/composables/useBookingGuests";
import type { BookingGuest } from "@/contexts/booking-guests/models/BookingGuest";
import type { SearchBookingGuestsCriteria } from "@/contexts/booking-guests/models/SearchBookingGuestsCriteria";
import ModalDialog from "@/contexts/shared/ui/components/modal/ModalDialog.vue";
import BookingGuestsCreateModal from "@/contexts/booking-guests/ui/modals/BookingGuestsCreateModal.vue";
import FontAwesomeIcon from "@/contexts/shared/ui/components/icon/FontAwesomeIcon.vue";
import FormAsyncSelect from "@/contexts/shared/ui/components/form/FormAsyncSelect.vue";

defineProps<{
  modelValue: string | null;
}>();

const emit = defineEmits<{
  "update:modelValue": [value: string | null];
}>();

const { t: $t } = useI18n();
const { searchBookingGuests, showBookingGuest } = useBookingGuests();

const criteria = ref<SearchBookingGuestsCriteria>({ search: "" });

const createBookingGuestModal = ref<InstanceType<typeof ModalDialog>>();

const searchBookingGuestsRequest = useRequest<Pagination<BookingGuest>>({
  initialLoading: true,
  value: emptyPagination<BookingGuest>(),
  promise: () => searchBookingGuests(criteria.value),
});
const showSelectedBookingGuest = (id: string, onSelected: (item: BookingGuest) => void) => showBookingGuest(id).then((response) => onSelected(response));
const showCreateBookingGuestModal = () => createBookingGuestModal.value?.open();
const onCreatedBookingGuest = (bookingGuest: BookingGuest) => {
  emit("update:modelValue", bookingGuest.id);
  searchBookingGuestsRequest.fetch();
};
</script>
