<template>
  <ConfirmDeleteModal
    :is-loading="deleteAccountServiceRequest.isLoading"
    description="accounts.services.delete.description"
    title="accounts.services.delete.title"
    @close="$emit('close')"
    @confirm="deleteAccountServiceRequest.fetch()"
  />
</template>

<script lang="ts" setup>
import useNotification from "@/contexts/shared/composables/useNotification";
import useRequest from "@/contexts/shared/composables/useRequest";
import useAccountServices from "@/contexts/accounts/composables/useAccountServices";
import type { AccountService } from "@/contexts/accounts/models/AccountService";
import ConfirmDeleteModal from "@/contexts/shared/ui/modals/ConfirmDeleteModal.vue";

const props = defineProps<{
  accountService: AccountService;
}>();

const emit = defineEmits<{
  close: [];
  deleted: [];
}>();

const { t: $t } = useI18n();
const { deleteAccountService } = useAccountServices();
const { successNotification, errorNotification } = useNotification();

const deleteAccountServiceRequest = useRequest({
  promise: () => deleteAccountService(props.accountService.id),
  onSuccess: () => {
    successNotification("accounts.services.delete.success");
    emit("deleted");
    emit("close");
  },
  onFailure: () => {
    errorNotification("accounts.delete.error");
  },
});
</script>
