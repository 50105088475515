<template>
  <DashboardHeader>
    <div class="text-xl font-semibold text-gray-800">
      {{ $t("settings.title") }}
    </div>
  </DashboardHeader>
  <div class="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
    <SettingsSection
      description="settings.sections.account.description"
      icon="briefcase-duotone"
      title="settings.sections.account.title"
    >
      <SettingsSectionLink :to="{ name: 'settings.account' }">{{ $t("settings.account.title") }}</SettingsSectionLink>
      <SettingsSectionLink :to="{ name: 'settings.account.services' }">{{ $t("settings.account.services.title") }}</SettingsSectionLink>
      <SettingsSectionLink :to="{ name: 'settings.account.seasons' }">{{ $t("settings.account.seasons.title") }}</SettingsSectionLink>
    </SettingsSection>
    <SettingsSection
      description="settings.sections.integrations.description"
      icon="plug-duotone"
      title="settings.sections.integrations.title"
    >
      <SettingsSectionLink :to="{ name: 'settings.integrations' }">{{ $t("settings.integrations.title") }}</SettingsSectionLink>
    </SettingsSection>
  </div>
</template>

<script lang="ts" setup>
import DashboardHeader from "@/contexts/shared/ui/components/header/DashboardHeader.vue";
import SettingsSection from "@/contexts/settings/ui/components/SettingsSection.vue";
import SettingsSectionLink from "@/contexts/settings/ui/components/SettingsSectionLink.vue";

const { t: $t } = useI18n();
useHead({ title: () => `${$t("settings.title")} - Hussbook` });
</script>
