<template>
  <Widget large>
    <WidgetTitle>
      {{ $t("analytics.bookings_by_week.title") }}
    </WidgetTitle>
    <WidgetContent>
      <div
        ref="target"
        class="h-96"
      >
        <template v-if="bookingsByWeekRequest.isLoading">
          <LoadingSection
            class="h-full"
            custom-height
          />
        </template>
        <template v-else-if="bookingsByWeekRequest.hasErrors">
          <ListErrorState
            class="h-full"
            custom-height
          />
        </template>
        <template v-else>
          <div class="h-full p-2">
            <LineChart
              :labels="chartLabels"
              :values="chartValues"
              :options="chartOptions"
            >
              <template #tooltipTitle="{ value }">
                {{
                  $t("analytics.bookings_by_week.labels.week", {
                    start: dayjs()
                      .year(Number(value?.split("-")[1]))
                      .week(Number(value?.split("-")[0]))
                      .startOf("week")
                      .format("L"),
                    end: dayjs()
                      .year(Number(value?.split("-")[1]))
                      .week(Number(value?.split("-")[0]))
                      .endOf("week")
                      .format("L"),
                  })
                }}
              </template>
            </LineChart>
          </div>
        </template>
      </div>
      <div class="-mt-2 flex flex-col space-y-3.5 px-4 pb-3 md:flex-row md:justify-end md:space-x-3.5 md:space-y-0">
        <div class="flex items-center space-x-2">
          <FormCheckbox
            id="display_departures"
            v-model="departures"
            name="display_departures"
          />
          <FormLabel for="display_departures">{{ $t("analytics.bookings_by_week.display_departures") }}</FormLabel>
        </div>
        <div class="flex items-center space-x-2">
          <FormCheckbox
            id="display_previous"
            v-model="previous"
            name="display_previous"
          />
          <FormLabel for="display_previous">{{ $t("analytics.bookings_by_week.display_previous") }}</FormLabel>
        </div>
      </div>
    </WidgetContent>
  </Widget>
</template>

<script lang="ts" setup>
import Widget from "@/contexts/shared/ui/components/widget/Widget.vue";
import WidgetTitle from "@/contexts/shared/ui/components/widget/WidgetTitle.vue";
import WidgetContent from "@/contexts/shared/ui/components/widget/WidgetContent.vue";
import useRequest from "@/contexts/shared/composables/useRequest";
import useVisibility from "@/contexts/shared/composables/useVisibility";
import { useEventBus } from "@vueuse/core";
import type { DataSet } from "@/contexts/shared/ui/components/charts/LineChart.vue";
import LineChart from "@/contexts/shared/ui/components/charts/LineChart.vue";
import useChart from "@/contexts/shared/composables/useChart";
import ListErrorState from "@/contexts/shared/ui/components/list/ListErrorState.vue";
import LoadingSection from "@/contexts/shared/ui/components/loading/LoadingSection.vue";
import useAnalytics from "@/contexts/analytics/composables/useAnalytics";
import useDayjs from "@/contexts/shared/composables/useDayjs";
import FormCheckbox from "@/contexts/shared/ui/components/form/FormCheckbox.vue";
import FormLabel from "@/contexts/shared/ui/components/form/FormLabel.vue";
import type { ChartOptions } from "chart.js";

const { t: $t } = useI18n();
const dayjs = useDayjs();
const { colorVar } = useChart();
const { bookingsByWeek } = useAnalytics();
const accountBus = useEventBus<string>("account");

const previous = ref<boolean>(false);
const departures = ref<boolean>(true);
const target = ref<HTMLElement>();

const visibility = useVisibility(target, () => bookingsByWeekRequest.fetch());

accountBus.on(() => visibility.reset());

const bookingsByWeekRequest = useRequest({
  initialLoading: true,
  value: { labels: [], values: { current: [], previous: [] } },
  promise: () => bookingsByWeek(),
});

const chartLabels = computed(() => bookingsByWeekRequest.data.labels);
const chartValues = computed<DataSet[]>(() => {
  const values: DataSet[] = [{ values: bookingsByWeekRequest.data.values.current.map((item) => item.arrivals), label: "analytics.bookings_by_week.values.current.arrivals", color: colorVar("--color-primary") }];
  if (departures.value) {
    values.push({ values: bookingsByWeekRequest.data.values.current.map((item) => item.departures), label: "analytics.bookings_by_week.values.current.departures", color: colorVar("--color-secondary") });
  }

  if (previous.value) {
    values.push({ values: bookingsByWeekRequest.data.values.previous.map((item) => item.arrivals), label: "analytics.bookings_by_week.values.previous.arrivals", color: colorVar("--color-primary"), dashed: true });
  }
  if (previous.value && departures.value) {
    values.push({ values: bookingsByWeekRequest.data.values.previous.map((item) => item.departures), label: "analytics.bookings_by_week.values.previous.departures", color: colorVar("--color-secondary"), dashed: true });
  }

  return values;
});
const chartOptions = computed<Partial<ChartOptions<"line">>>(() => ({
  scales: {
    x: {
      ticks: {
        callback: (value) => chartLabels.value[Number(value)].split("-")[0],
      },
    },
  },
}));
</script>
