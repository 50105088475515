<template>
  <ListTable>
    <ListTableHeader>
      <ListTableHeaderCell primary>
        {{ $t("cleaning_groups.props.name") }}
      </ListTableHeaderCell>
      <ListTableHeaderCell optional>
        {{ $t("cleaning_groups.props.created_at") }}
      </ListTableHeaderCell>
      <ListTableHeaderCell optional>
        {{ $t("cleaning_groups.props.updated_at") }}
      </ListTableHeaderCell>
      <ListTableHeaderCell small />
    </ListTableHeader>
    <ListTableBody>
      <template
        v-for="cleaningGroup in cleaningGroups"
        :key="cleaningGroup.id"
      >
        <ListTableBodyRowLink :to="{ name: 'cleaning_groups.manage', params: { id: cleaningGroup.id } }">
          <ListTableBodyCell>
            {{ cleaningGroup.name }}
            <Badge
              v-if="cleaningGroup.archived_at"
              class="badge-gray ml-2"
            >
              {{ $t("shared.archived") }}
            </Badge>
          </ListTableBodyCell>
          <ListTableBodyCell optional>
            {{ cleaningGroup.created_at.toLocaleString() }}
          </ListTableBodyCell>
          <ListTableBodyCell optional>
            {{ cleaningGroup.updated_at.toLocaleString() }}
          </ListTableBodyCell>
          <ListTableBodyCell
            :padding="false"
            :truncate="false"
            class="pl-2.5"
          >
            <CleaningGroupsDropdown
              :cleaning-group="cleaningGroup"
              @deleted="$emit('deleted')"
              @updated="$emit('updated')"
              @archived="$emit('archived')"
              @unarchived="$emit('unarchived')"
            />
          </ListTableBodyCell>
        </ListTableBodyRowLink>
      </template>
    </ListTableBody>
  </ListTable>
</template>

<script lang="ts" setup>
import ListTable from "@/contexts/shared/ui/components/list/ListTable.vue";
import ListTableHeader from "@/contexts/shared/ui/components/list/ListTableHeader.vue";
import ListTableHeaderCell from "@/contexts/shared/ui/components/list/ListTableHeaderCell.vue";
import ListTableBody from "@/contexts/shared/ui/components/list/ListTableBody.vue";
import type { CleaningGroup } from "@/contexts/cleaning-groups/models/CleaningGroup";
import ListTableBodyCell from "@/contexts/shared/ui/components/list/ListTableBodyCell.vue";
import ListTableBodyRowLink from "@/contexts/shared/ui/components/list/ListTableBodyRowLink.vue";
import CleaningGroupsDropdown from "@/contexts/cleaning-groups/ui/components/CleaningGroupsDropdown.vue";
import Badge from "@/contexts/shared/ui/components/badge/Badge.vue";

defineProps<{
  cleaningGroups: CleaningGroup[];
}>();

defineEmits<{
  updated: [];
  archived: [];
  unarchived: [];
  deleted: [];
}>();

const { t: $t } = useI18n();
</script>
