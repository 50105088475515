<template>
  <ListDetails>
    <ListDetailsColumn>
      <ListDetailsCell label="booking_guests.props.name">
        {{ guest.name }}
      </ListDetailsCell>
      <ListDetailsCell label="booking_guests.props.language">
        {{ guest.language ? $t(`shared.language.${guest.language.toLowerCase()}`) : "-" }}
      </ListDetailsCell>
      <ListDetailsCell label="booking_guests.props.created_at">
        {{ guest.created_at.toLocaleString() }}
      </ListDetailsCell>
    </ListDetailsColumn>
    <ListDetailsColumn>
      <ListDetailsCell label="booking_guests.props.email">
        <template v-for="email in guest.emails">
          {{ email }}
        </template>
      </ListDetailsCell>
      <ListDetailsCell label="booking_guests.props.phone">
        <template v-for="phone in guest.phones">
          {{ phone }}
        </template>
      </ListDetailsCell>
    </ListDetailsColumn>
  </ListDetails>
</template>

<script lang="ts" setup>
import ListDetails from "@/contexts/shared/ui/components/list/ListDetails.vue";
import ListDetailsColumn from "@/contexts/shared/ui/components/list/ListDetailsColumn.vue";
import ListDetailsCell from "@/contexts/shared/ui/components/list/ListDetailsCell.vue";
import type { BookingGuest } from "@/contexts/booking-guests/models/BookingGuest";

defineProps<{
  guest: BookingGuest;
}>();

const { t: $t } = useI18n();
</script>
