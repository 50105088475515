<template>
  <ListTable>
    <ListTableHeader>
      <ListTableHeaderCell primary>{{ $t("accounts.services.props.name") }}</ListTableHeaderCell>
      <ListTableHeaderCell optional>{{ $t("accounts.services.props.price") }}</ListTableHeaderCell>
      <ListTableHeaderCell optional>{{ $t("accounts.services.props.type") }}</ListTableHeaderCell>
      <ListTableHeaderCell optional>{{ $t("accounts.services.props.unit") }}</ListTableHeaderCell>
      <ListTableHeaderCell optional>{{ $t("accounts.services.props.created_at") }}</ListTableHeaderCell>
      <ListTableHeaderCell optional>{{ $t("accounts.services.props.updated_at") }}</ListTableHeaderCell>
      <ListTableHeaderCell small />
    </ListTableHeader>
    <ListTableBody>
      <template
        v-for="service in accountServices"
        :key="service.id"
      >
        <ListTableBodyRow>
          <ListTableBodyCell>{{ service.name[locale] ?? service.name["en"] ?? service.name[Object.keys(service.name)[0]] ?? "-" }}</ListTableBodyCell>
          <ListTableBodyCell optional>{{ service.price ? Number(String(service.price)).toFixed(2) : "-" }}</ListTableBodyCell>
          <ListTableBodyCell optional>{{ service.type ? $t(`accounts.services.types.${service.type}`) : "-" }}</ListTableBodyCell>
          <ListTableBodyCell optional>{{ service.unit ? $t(`accounts.services.units.${service.unit}`) : "-" }}</ListTableBodyCell>
          <ListTableBodyCell optional>{{ service.created_at.toLocaleString() }}</ListTableBodyCell>
          <ListTableBodyCell optional>{{ service.updated_at.toLocaleString() }}</ListTableBodyCell>
          <ListTableBodyCell
            :padding="false"
            :truncate="false"
            class="pl-2.5"
          >
            <AccountServicesDropdown
              :account-service="service"
              @deleted="$emit('deleted')"
              @updated="$emit('updated')"
            />
          </ListTableBodyCell>
        </ListTableBodyRow>
      </template>
    </ListTableBody>
  </ListTable>
</template>

<script lang="ts" setup>
import ListTableHeaderCell from "@/contexts/shared/ui/components/list/ListTableHeaderCell.vue";
import ListTableBody from "@/contexts/shared/ui/components/list/ListTableBody.vue";
import ListTableHeader from "@/contexts/shared/ui/components/list/ListTableHeader.vue";
import ListTableBodyCell from "@/contexts/shared/ui/components/list/ListTableBodyCell.vue";
import ListTable from "@/contexts/shared/ui/components/list/ListTable.vue";
import ListTableBodyRow from "@/contexts/shared/ui/components/list/ListTableBodyRow.vue";
import type { AccountService } from "@/contexts/accounts/models/AccountService";
import AccountServicesDropdown from "@/contexts/accounts/ui/components/AccountServicesDropdown.vue";

defineProps<{
  accountServices: AccountService[];
}>();

defineEmits<{
  updated: [];
  deleted: [];
}>();

const { t: $t, locale } = useI18n();
</script>
