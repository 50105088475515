<template>
  <div class="z-20 grid w-full max-w-xs rounded-md border border-gray-200 bg-white px-2.5 py-2 shadow-lg">
    <div class="mb-2">
      <div class="mb-1 font-medium text-gray-800">{{ $t("accommodations.prices.form.title") }}</div>
      <div class="text-sm text-gray-500">{{ `${from?.toLocaleDateString()} - ${to?.toLocaleDateString()}` }}</div>
    </div>
    <form
      class="space-y-3"
      @submit.prevent="updateAccommodationsPricesRequest.fetch"
    >
      <AccommodationsPricesInput
        v-model="form.prices"
        :errors="updateAccommodationsPricesRequest.errors.prices"
      />
      <FormGroup
        id="`minimum_stay"
        :errors="updateAccommodationsPricesRequest.errors.minimum_stay"
      >
        <FormLabel>{{ $t("accommodations.prices.minimum_stay") }}</FormLabel>
        <FormInteger
          v-model="form.minimum_stay"
          :min="1"
          name="`minimum_stay"
        />
      </FormGroup>
      <button
        class="btn-primary"
        type="submit"
      >
        {{ $t("accommodations.prices.form.submit") }}
      </button>
    </form>
  </div>
</template>

<script setup lang="ts">
import useDayjs from "@/contexts/shared/composables/useDayjs";
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import AccommodationsPricesInput from "@/contexts/accommodations/ui/components/AccommodationsPricesInput.vue";
import type { UpdateAccommodationsPrices } from "@/contexts/accommodations/models/UpdateAccommodationsPrices";
import useRequest from "@/contexts/shared/composables/useRequest";
import useAccommodations from "@/contexts/accommodations/composables/useAccommodations";
import FormGroup from "@/contexts/shared/ui/components/form/FormGroup.vue";
import FormLabel from "@/contexts/shared/ui/components/form/FormLabel.vue";
import FormInteger from "@/contexts/shared/ui/components/form/FormInteger.vue";

const props = defineProps<{
  selected: { accommodation: Accommodation; value: [Date, Date] } | undefined;
}>();

const emit = defineEmits<{
  updated: [];
}>();

const { t: $t } = useI18n();
const dayjs = useDayjs();
const { updateAccommodationsPrices } = useAccommodations();

const form = ref<UpdateAccommodationsPrices>({
  accommodations: [null],
  date: { from: null, to: null },
  prices: {},
  minimum_stay: null,
});
const from = computed<Date | undefined>(() => dayjs(props.selected?.value?.[0]).toDate());
const to = computed<Date | undefined>(() => dayjs(props.selected?.value?.[1]).toDate());

const updateAccommodationsPricesRequest = useRequest({
  promise: () => updateAccommodationsPrices(form.value),
  onSuccess: () => {
    // todo success notification
    emit("updated");
  },
  onFailure: () => {
    // todo failure notification
  },
});

watch(
  () => props.selected,
  () => {
    if (props.selected) {
      form.value.accommodations = [props.selected.accommodation.id];
      form.value.date.from = props.selected?.value[0];
      form.value.date.to = props.selected?.value[1];
    }
  },
);
</script>
