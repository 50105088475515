<template>
  <div>
    <template v-if="accommodations?.length === 0">
      <SectionEmptyState>
        {{ $t("owners.manage.accommodations.empty") }}
      </SectionEmptyState>
    </template>
    <template v-else>
      <div class="space-y-3">
        <ListTable>
          <ListTableHeader>
            <ListTableHeaderCell primary>{{ $t("accommodations.props.name") }}</ListTableHeaderCell>
            <ListTableHeaderCell>{{ $t("accommodations.props.capacity") }}</ListTableHeaderCell>
            <ListTableHeaderCell optional>{{ $t("accommodations.props.bedrooms") }}</ListTableHeaderCell>
            <ListTableHeaderCell optional>{{ $t("accommodations.props.bathrooms") }}</ListTableHeaderCell>
          </ListTableHeader>
          <ListTableBody>
            <template
              v-for="accommodation in accommodations"
              :key="accommodation.id"
            >
              <ListTableBodyRowLink :to="{ name: 'accommodations.manage', params: { id: accommodation.id } }">
                <ListTableBodyCell>
                  {{ accommodation.acronym }}
                  <Badge
                    v-if="accommodation.archived_at"
                    class="badge-gray ml-2"
                  >
                    {{ $t("shared.archived") }}
                  </Badge>
                </ListTableBodyCell>
                <ListTableBodyCell>
                  {{ $t("accommodations.manage.capacity", accommodation?.capacity ?? 0) }}
                </ListTableBodyCell>
                <ListTableBodyCell optional>
                  {{ $t("accommodations.manage.bedrooms.count.bedrooms", accommodation?.bedrooms_count ?? 0) }}
                </ListTableBodyCell>
                <ListTableBodyCell optional>
                  {{ $t("accommodations.manage.bathrooms.count", accommodation?.bathrooms_count ?? 0) }}
                </ListTableBodyCell>
              </ListTableBodyRowLink>
            </template>
          </ListTableBody>
        </ListTable>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import SectionEmptyState from "@/contexts/shared/ui/components/section/SectionEmptyState.vue";
import ListTableBodyRowLink from "@/contexts/shared/ui/components/list/ListTableBodyRowLink.vue";
import ListTableBody from "@/contexts/shared/ui/components/list/ListTableBody.vue";
import ListTableBodyCell from "@/contexts/shared/ui/components/list/ListTableBodyCell.vue";
import ListTableHeaderCell from "@/contexts/shared/ui/components/list/ListTableHeaderCell.vue";
import ListTableHeader from "@/contexts/shared/ui/components/list/ListTableHeader.vue";
import ListTable from "@/contexts/shared/ui/components/list/ListTable.vue";
import Badge from "@/contexts/shared/ui/components/badge/Badge.vue";

defineProps<{
  accommodations: Accommodation[] | undefined;
}>();

const { t: $t } = useI18n();
</script>
