<template>
  <form
    class="space-y-3.5"
    @submit.prevent="submit"
  >
    <FormGroup :errors="errors.name">
      <FormLabel>{{ $t("accounts.services.props.name") }}</FormLabel>
      <FormInput>
        <FormTranslatableText v-model="input.name" />
      </FormInput>
    </FormGroup>
    <FormGroup :errors="errors.price">
      <FormLabel>{{ $t("accounts.services.props.price") }}</FormLabel>
      <FormInput>
        <FormText
          v-model.number="input.price"
          name="price"
          type="number"
        />
      </FormInput>
    </FormGroup>
    <FormGroup :errors="errors.type">
      <FormLabel>{{ $t("accounts.services.props.type") }}</FormLabel>
      <FormInput>
        <FormSelect
          v-model="input.type"
          :options="serviceTypeOptions"
          name="type"
          nullable
        />
      </FormInput>
    </FormGroup>
    <FormGroup :errors="errors.unit">
      <FormLabel>{{ $t("accounts.services.props.unit") }}</FormLabel>
      <FormInput>
        <FormSelect
          v-model="input.unit"
          :options="serviceUnitOptions"
          name="unit"
          nullable
        />
      </FormInput>
    </FormGroup>
    <input
      class="hidden"
      type="submit"
    />
  </form>
</template>

<script lang="ts" setup>
import type { RequestErrors } from "@/contexts/shared/composables/useRequest";
import type { UnsavedAccountService } from "@/contexts/accounts/models/AccountService";
import FormLabel from "@/contexts/shared/ui/components/form/FormLabel.vue";
import FormGroup from "@/contexts/shared/ui/components/form/FormGroup.vue";
import FormText from "@/contexts/shared/ui/components/form/FormText.vue";
import FormInput from "@/contexts/shared/ui/components/form/FormInput.vue";
import FormSelect from "@/contexts/shared/ui/components/form/FormSelect.vue";
import type { Option } from "@/contexts/shared/models/Select";
import FormTranslatableText from "@/contexts/shared/ui/components/form/FormTranslatableText.vue";

const props = defineProps<{
  modelValue: UnsavedAccountService;
  errors: RequestErrors<UnsavedAccountService>;
}>();

const emit = defineEmits<{
  submit: [];
  "update:modelValue": [value: UnsavedAccountService];
}>();

const { t: $t } = useI18n();

const input = computed<UnsavedAccountService>({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});
const serviceTypeOptions = computed<Option[]>(() => ["check_in", "check_out", "extra"].map((item) => ({ value: item, label: $t(`accounts.services.types.${item}`) })));
const serviceUnitOptions = computed<Option[]>(() => ["day", "week", "month", "booking"].map((item) => ({ value: item, label: $t(`accounts.services.units.${item}`) })));

const submit = () => {
  emit("submit");
};
</script>
