<template>
  <ModalDialogHeader>
    <ModalDialogTitle>{{ $t("accommodations.update.bathrooms") }}</ModalDialogTitle>
  </ModalDialogHeader>
  <ModalDialogContent>
    <form @submit.prevent="submit">
      <div class="space-y-2.5">
        <template
          v-for="(item, index) in input"
          :key="index"
        >
          <div class="relative gap-2 rounded border border-gray-200 p-3">
            <ButtonWithIcon
              class="absolute right-0 top-0 m-1 p-2"
              icon="trash-can-solid"
              @click="removeBathroom(index)"
            />
            <div class="text-sm font-medium text-gray-800">{{ $t(`accommodations.manage.bathrooms.name.bathroom`, { number: index + 1 }) }}</div>
            <div class="mt-2 grid gap-2 md:grid-cols-4">
              <div class="grid gap-2">
                <FormGroup :errors="updateAccommodationBathroomsRequest.errors?.[`bathrooms.${index}.type`]">
                  <FormLabel>{{ $t("accommodations.manage.bathrooms.props.type") }}</FormLabel>
                  <FormInput>
                    <FormSelect
                      v-model="input[index].type"
                      :options="bathroomTypeOptions"
                      name="type"
                    />
                  </FormInput>
                </FormGroup>
                <FormGroup :errors="updateAccommodationBathroomsRequest.errors?.[`bedrooms.${index}.floor`]">
                  <FormLabel>{{ $t("accommodations.manage.bathrooms.props.floor") }}</FormLabel>
                  <FormInput>
                    <FormInteger
                      v-model="input[index].floor"
                      name="floor"
                    />
                  </FormInput>
                </FormGroup>
              </div>
              <div class="grid gap-2 md:col-span-3 md:grid-cols-3">
                <FormGroup :errors="updateAccommodationBathroomsRequest.errors?.[`bathrooms.${index}.toilet`]">
                  <FormLabel>{{ $t("accommodations.manage.bathrooms.props.toilet") }}</FormLabel>
                  <FormInput>
                    <FormSelect
                      v-model="input[index].toilet"
                      :options="bathroomToiletTypeOptions"
                      name="toilet"
                    />
                  </FormInput>
                </FormGroup>
                <FormGroup :errors="updateAccommodationBathroomsRequest.errors?.[`bathrooms.${index}.shower`]">
                  <FormLabel>{{ $t("accommodations.manage.bathrooms.props.shower") }}</FormLabel>
                  <FormInput>
                    <FormSelect
                      v-model="input[index].shower"
                      :options="bathroomShowerTypeOptions"
                      name="shower"
                    />
                  </FormInput>
                </FormGroup>
                <FormGroup :errors="updateAccommodationBathroomsRequest.errors?.[`bathrooms.${index}.bath`]">
                  <FormLabel>{{ $t("accommodations.manage.bathrooms.props.bath") }}</FormLabel>
                  <FormInput>
                    <FormSelect
                      v-model="input[index].bath"
                      :options="bathroomBathTypeOptions"
                      name="bath"
                    />
                  </FormInput>
                </FormGroup>
              </div>
            </div>
          </div>
        </template>
        <div class="flex justify-center md:col-span-2">
          <ButtonWithIcon
            class="btn-primary"
            icon="plus-solid"
            @click="addBathroom"
          >
            {{ $t("accommodations.manage.bathrooms.add") }}
          </ButtonWithIcon>
        </div>
      </div>
      <input
        class="hidden"
        type="submit"
      />
    </form>
  </ModalDialogContent>
  <ModalDialogFooter>
    <button
      class="btn-primary"
      type="submit"
      @click.prevent="submit"
    >
      {{ $t("shared.form.save") }}
    </button>
    <button
      class="btn-white"
      type="button"
      @click.prevent="$emit('close')"
    >
      {{ $t("shared.form.cancel") }}
    </button>
  </ModalDialogFooter>
</template>

<script lang="ts" setup>
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import ModalDialogHeader from "@/contexts/shared/ui/components/modal/ModalDialogHeader.vue";
import ModalDialogContent from "@/contexts/shared/ui/components/modal/ModalDialogContent.vue";
import ModalDialogTitle from "@/contexts/shared/ui/components/modal/ModalDialogTitle.vue";
import ModalDialogFooter from "@/contexts/shared/ui/components/modal/ModalDialogFooter.vue";
import type { AccommodationBathroom, UnsavedAccommodationBathroom } from "@/contexts/accommodations/models/AccommodationRooms";
import ButtonWithIcon from "@/contexts/shared/ui/components/button/ButtonWithIcon.vue";
import { cloneDeep } from "lodash-es";
import FormGroup from "@/contexts/shared/ui/components/form/FormGroup.vue";
import FormLabel from "@/contexts/shared/ui/components/form/FormLabel.vue";
import FormSelect from "@/contexts/shared/ui/components/form/FormSelect.vue";
import FormInput from "@/contexts/shared/ui/components/form/FormInput.vue";
import type { Option } from "@/contexts/shared/models/Select";
import useAccommodations from "@/contexts/accommodations/composables/useAccommodations";
import useNotification from "@/contexts/shared/composables/useNotification";
import useRequest from "@/contexts/shared/composables/useRequest";
import FormInteger from "@/contexts/shared/ui/components/form/FormInteger.vue";

const props = defineProps<{
  accommodation: Accommodation;
  bathrooms: AccommodationBathroom[];
}>();

const emit = defineEmits<{
  close: [];
  updated: [value: Accommodation];
}>();

const { t: $t } = useI18n();
const { updateAccommodationBathrooms } = useAccommodations();
const { successNotification, errorNotification } = useNotification();

onMounted(() => {
  input.value = cloneDeep(props.bathrooms);
});

const input = ref<UnsavedAccommodationBathroom[]>([]);
const bathroomTypeOptions = computed<Option[]>(() => ["wc", "full", "en_suite"].map((item) => ({ value: item, label: $t(`accommodations.manage.bathrooms.type.${item}`) })));
const bathroomToiletTypeOptions = computed<Option[]>(() => ["toilet", "no_toilet"].map((item) => ({ value: item, label: $t(`accommodations.manage.bathrooms.toilet.type.${item}`) })));
const bathroomShowerTypeOptions = computed<Option[]>(() => ["over_bath", "separate", "no_shower"].map((item) => ({ value: item, label: $t(`accommodations.manage.bathrooms.shower.type.${item}`) })));
const bathroomBathTypeOptions = computed<Option[]>(() => ["standard", "whirlpool", "jacuzzi", "no_bath"].map((item) => ({ value: item, label: $t(`accommodations.manage.bathrooms.bath.type.${item}`) })));

const updateAccommodationBathroomsRequest = useRequest({
  promise: () => updateAccommodationBathrooms(props.accommodation.id, input.value),
  onSuccess: (response) => {
    successNotification("accommodations.update.success");
    emit("updated", response);
    emit("close");
  },
  onFailure: (response) => {
    if (response.status !== 422) {
      errorNotification("accommodations.update.error");
    }
  },
});

const addBathroom = () => {
  input.value.push({ type: "full", floor: null, toilet: "toilet", shower: "separate", bath: "no_bath" });
};
const removeBathroom = (index: number) => {
  input.value.splice(index, 1);
};
const submit = async () => {
  await updateAccommodationBathroomsRequest.fetch();
};
</script>
