<template>
  <div ref="target">
    <template v-if="searchAccommodationsRequest.isLoading">
      <LoadingSection />
    </template>
    <template v-else-if="searchAccommodationsRequest.hasErrors">
      <ListErrorState />
    </template>
    <template v-else-if="searchAccommodationsRequest.data.data.length === 0">
      <SectionEmptyState>
        {{ $t("cleaning_groups.manage.accommodations.empty") }}
      </SectionEmptyState>
    </template>
    <template v-else>
      <div class="space-y-3">
        <ListTable>
          <ListTableHeader>
            <ListTableHeaderCell primary>{{ $t("accommodations.props.name") }}</ListTableHeaderCell>
            <ListTableHeaderCell>{{ $t("accommodations.props.capacity") }}</ListTableHeaderCell>
            <ListTableHeaderCell optional>{{ $t("accommodations.props.bedrooms") }}</ListTableHeaderCell>
            <ListTableHeaderCell optional>{{ $t("accommodations.props.bathrooms") }}</ListTableHeaderCell>
          </ListTableHeader>
          <ListTableBody>
            <template
              v-for="accommodation in searchAccommodationsRequest.data.data"
              :key="accommodation.id"
            >
              <ListTableBodyRowLink :to="{ name: 'accommodations.manage', params: { id: accommodation.id } }">
                <ListTableBodyCell>
                  {{ accommodation.acronym }}
                  <Badge
                    v-if="accommodation.archived_at"
                    class="badge-gray ml-2"
                  >
                    {{ $t("shared.archived") }}
                  </Badge>
                </ListTableBodyCell>
                <ListTableBodyCell>
                  {{ $t("accommodations.manage.capacity", accommodation?.capacity ?? 0) }}
                </ListTableBodyCell>
                <ListTableBodyCell optional>
                  {{ $t("accommodations.manage.bedrooms.count.bedrooms", accommodation?.bedrooms_count ?? 0) }}
                </ListTableBodyCell>
                <ListTableBodyCell optional>
                  {{ $t("accommodations.manage.bathrooms.count", accommodation?.bathrooms_count ?? 0) }}
                </ListTableBodyCell>
              </ListTableBodyRowLink>
            </template>
          </ListTableBody>
        </ListTable>
        <CursorPagination
          :meta="searchAccommodationsRequest.data.meta"
          :total="searchAccommodationsRequest.data.total"
          @change-page="changePage"
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import LoadingSection from "@/contexts/shared/ui/components/loading/LoadingSection.vue";
import ListErrorState from "@/contexts/shared/ui/components/list/ListErrorState.vue";
import SectionEmptyState from "@/contexts/shared/ui/components/section/SectionEmptyState.vue";
import CursorPagination from "@/contexts/shared/ui/components/pagination/CursorPagination.vue";
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import useCriteria from "@/contexts/shared/composables/useCriteria";
import useVisibility from "@/contexts/shared/composables/useVisibility";
import useRequest, { type UseRequest } from "@/contexts/shared/composables/useRequest";
import { emptyPagination, type Pagination } from "@/contexts/shared/models/Pagination";
import type { CleaningGroup } from "@/contexts/cleaning-groups/models/CleaningGroup";
import useAccommodations from "@/contexts/accommodations/composables/useAccommodations";
import type { SearchAccommodationsCriteria } from "@/contexts/accommodations/models/SearchAccommodationsCriteria";
import ListTableBodyRowLink from "@/contexts/shared/ui/components/list/ListTableBodyRowLink.vue";
import ListTableBody from "@/contexts/shared/ui/components/list/ListTableBody.vue";
import ListTableBodyCell from "@/contexts/shared/ui/components/list/ListTableBodyCell.vue";
import ListTableHeaderCell from "@/contexts/shared/ui/components/list/ListTableHeaderCell.vue";
import ListTableHeader from "@/contexts/shared/ui/components/list/ListTableHeader.vue";
import ListTable from "@/contexts/shared/ui/components/list/ListTable.vue";
import Badge from "@/contexts/shared/ui/components/badge/Badge.vue";

const props = defineProps<{
  cleaningGroup: CleaningGroup;
}>();

const { t: $t } = useI18n();
const { searchAccommodations } = useAccommodations();

const target = ref<HTMLElement>();
const { criteria, cursor, changePage } = useCriteria<SearchAccommodationsCriteria>({ cleaning_group_id: String(props.cleaningGroup.id), per_page: 10 }, () => searchAccommodationsRequest.fetch());
useVisibility(target, () => searchAccommodationsRequest.fetch());

const searchAccommodationsRequest: UseRequest<Pagination<Accommodation>> = useRequest<Pagination<Accommodation>>({
  initialLoading: true,
  value: emptyPagination<Accommodation>(),
  promise: () => searchAccommodations(criteria.value, cursor.value),
  onSuccess: (response) => {
    if (cursor.value && response.data.length === 0) {
      changePage();
      return;
    }
  },
});
</script>
