<template>
  {{ $t(provider ? `shared.providers.${provider}` : "bookings.direct") }}
</template>

<script setup lang="ts">
import type { BookingProvider } from "@/contexts/bookings/models/Booking";

defineProps<{
  provider: BookingProvider | null;
}>();

const { t: $t } = useI18n();
</script>
