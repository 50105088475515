<template>
  <template v-if="showAccommodationRequest.isLoading">
    <LoadingSection />
  </template>
  <template v-else-if="showAccommodationRequest.hasErrors">
    <ListErrorState />
  </template>
  <template v-else-if="showAccommodationRequest.data">
    <DashboardHeader>
      <DashboardBackTo :to="{ name: 'accommodations' }">
        {{ $t("accommodations.manage.back_to_list") }}
      </DashboardBackTo>
      <DashboardHeaderTitle>{{ $t("accommodations.manage.title") }}</DashboardHeaderTitle>
      <DashboardHeaderActions>
        <ClipboardCopyId :value="showAccommodationRequest.data.id" />
        <AccommodationsDropdown
          :accommodation="showAccommodationRequest.data"
          @archived="showAccommodationRequest.fetch()"
          @deleted="router.push({ name: 'accommodations' })"
          @unarchived="showAccommodationRequest.fetch()"
          @published="showAccommodationRequest.fetch()"
          @updated-availability="accommodationsCalendarElement?.fetch()"
        >
          <DropdownMenuButton v-slot="{ toggle }">
            <button
              type="button"
              class="btn-white"
              @click="toggle"
            >
              <FontAwesomeIcon
                class="h-5 w-5 fill-gray-700"
                icon="ellipsis-light"
              />
            </button>
          </DropdownMenuButton>
        </AccommodationsDropdown>
      </DashboardHeaderActions>
    </DashboardHeader>
    <SectionGroup>
      <section v-if="showAccommodationRequest.data.archived_at">
        <SharedAlert
          description="accommodations.archived.description"
          title="accommodations.archived.title"
          variant="info"
        />
      </section>
      <section>
        <SectionHeader>
          <SectionTitle>{{ $t("accommodations.manage.details.title") }}</SectionTitle>
          <div>
            <ButtonWithIcon
              class="btn-white"
              icon="pen-solid"
              @click="accommodationUpdateDetailsModal?.open()"
            >
              {{ $t("accommodations.manage.details.edit") }}
            </ButtonWithIcon>
          </div>
        </SectionHeader>
        <AccommodationsDetails :accommodation="showAccommodationRequest.data" />
      </section>
      <div class="space-y-4 xl:grid xl:grid-cols-3 xl:gap-4 xl:space-y-0">
        <section>
          <SectionHeader>
            <SectionTitle>{{ $t("accommodations.manage.bedrooms.title") }}</SectionTitle>
            <div>
              <ButtonWithIcon
                class="btn-white"
                icon="bed-solid"
                @click="accommodationUpdateBedroomsModal?.open()"
              >
                {{ $t("accommodations.manage.bedrooms.edit") }}
              </ButtonWithIcon>
            </div>
          </SectionHeader>
          <AccommodationsBedrooms :bedrooms="showAccommodationRequest.data.bedrooms" />
        </section>
        <section>
          <SectionHeader>
            <SectionTitle>{{ $t("accommodations.manage.bathrooms.title") }}</SectionTitle>
            <div>
              <ButtonWithIcon
                class="btn-white"
                icon="bath-solid"
                @click="accommodationUpdateBathroomsModal?.open()"
              >
                {{ $t("accommodations.manage.bathrooms.edit") }}
              </ButtonWithIcon>
            </div>
          </SectionHeader>
          <AccommodationsBathrooms :bathrooms="showAccommodationRequest.data.bathrooms" />
        </section>
        <section>
          <SectionHeader>
            <SectionTitle>{{ $t("accommodations.manage.kitchens.title") }}</SectionTitle>
            <div>
              <ButtonWithIcon
                class="btn-white"
                icon="utensils-solid"
                @click="accommodationUpdateKitchensModal?.open()"
              >
                {{ $t("accommodations.manage.kitchens.edit") }}
              </ButtonWithIcon>
            </div>
          </SectionHeader>
          <AccommodationsKitchens :kitchens="showAccommodationRequest.data.kitchens" />
        </section>
      </div>
      <section>
        <SectionHeader>
          <SectionTitle>{{ $t("accommodations.manage.descriptions.title") }}</SectionTitle>
          <div>
            <ButtonWithIcon
              class="btn-white"
              icon="pen-solid"
              @click="accommodationUpdateDescriptionsModal?.open()"
            >
              {{ $t("accommodations.manage.descriptions.edit") }}
            </ButtonWithIcon>
          </div>
        </SectionHeader>
        <AccommodationsDescriptions :descriptions="showAccommodationRequest.data.descriptions" />
      </section>
      <section>
        <SectionHeader>
          <SectionTitle>{{ $t("accommodations.manage.facilities.title") }}</SectionTitle>
          <div>
            <ButtonWithIcon
              class="btn-white"
              icon="pen-solid"
              @click="accommodationUpdateFacilitiesModal?.open()"
            >
              {{ $t("accommodations.manage.facilities.edit") }}
            </ButtonWithIcon>
          </div>
        </SectionHeader>
        <AccommodationsFacilities :facilities="showAccommodationRequest.data.facilities" />
      </section>
      <section>
        <SectionHeader>
          <SectionTitle>{{ $t("accommodations.manage.extras.title") }}</SectionTitle>
          <div>
            <ButtonWithIcon
              class="btn-white"
              icon="pen-solid"
              @click="accommodationUpdateExtrasModal?.open()"
            >
              {{ $t("accommodations.manage.extras.edit") }}
            </ButtonWithIcon>
          </div>
        </SectionHeader>
        <AccommodationsExtras :extras="showAccommodationRequest.data.extras" />
      </section>
      <template v-if="showAccommodationRequest.data.owner">
        <section>
          <SectionHeader>
            <SectionTitle>{{ $t("accommodations.manage.owner.title") }}</SectionTitle>
          </SectionHeader>
          <ListDetails>
            <ListDetailsColumn>
              <ListDetailsCell label="owners.props.name">
                {{ showAccommodationRequest.data.owner.name }}
              </ListDetailsCell>
              <ListDetailsCell label="owners.props.language">
                {{ showAccommodationRequest.data.owner.language ? $t(`shared.language.${showAccommodationRequest.data.owner.language.toLowerCase()}`) : "-" }}
              </ListDetailsCell>
              <ListDetailsCell label="owners.props.created_at">
                {{ showAccommodationRequest.data.owner.created_at.toLocaleString() }}
              </ListDetailsCell>
            </ListDetailsColumn>
            <ListDetailsColumn>
              <ListDetailsCell label="owners.props.email">
                {{ showAccommodationRequest.data.owner.email }}
              </ListDetailsCell>
              <ListDetailsCell label="owners.props.phone">
                <template v-if="showAccommodationRequest.data.owner.phones?.length ?? 0 > 0">
                  <template v-for="phone in showAccommodationRequest.data.owner.phones">
                    {{ phone }}
                  </template>
                </template>
                <template v-else> -</template>
              </ListDetailsCell>
            </ListDetailsColumn>
          </ListDetails>
        </section>
      </template>
      <section>
        <SectionHeader>
          <SectionTitle>{{ $t("accommodations.manage.address.title") }}</SectionTitle>
          <div>
            <ButtonWithIcon
              class="btn-white"
              icon="pen-solid"
              @click="accommodationUpdateAddressModal?.open()"
            >
              {{ $t("accommodations.manage.address.edit") }}
            </ButtonWithIcon>
          </div>
        </SectionHeader>
        <AccommodationsAddress :accommodation="showAccommodationRequest.data" />
      </section>
      <section>
        <SectionHeader>
          <SectionTitle>{{ $t("accommodations.manage.photos.title") }}</SectionTitle>
          <div class="space-x-3">
            <ButtonWithIcon
              class="btn-white"
              icon="image-solid"
              @click="photosCreateModal?.open()"
            >
              {{ $t("accommodations.manage.photos.add") }}
            </ButtonWithIcon>
            <ButtonWithIcon
              :disabled="showAccommodationRequest.data.photos?.length === 0 || exportAccommodationPhotosRequest.isLoading"
              class="btn-white"
              icon="file-zipper-solid"
              @click="exportAccommodationPhotosRequest.fetch()"
            >
              {{ $t("accommodation_photos.export.title") }}
            </ButtonWithIcon>
          </div>
        </SectionHeader>
        <AccommodationPhotos
          :accommodation="showAccommodationRequest.data"
          :photos="showAccommodationRequest.data.photos"
          @deleted="showAccommodationRequest.data.photos = $event"
        />
      </section>
      <section>
        <SectionHeader>
          <SectionTitle>{{ $t("accommodations.manage.attachments.title") }}</SectionTitle>
          <div>
            <ButtonWithIcon
              class="btn-white"
              icon="paperclip-solid"
              @click="attachmentCreateModal?.open()"
            >
              {{ $t("accommodations.manage.attachments.add") }}
            </ButtonWithIcon>
          </div>
        </SectionHeader>
        <AccommodationAttachmentsTable
          :accommodation="showAccommodationRequest.data"
          :attachments="showAccommodationRequest.data.attachments"
          @deleted="getAccommodationAttachments.fetch()"
          @selected-default="showAccommodationRequest.data.default_attachment_id = $event"
        />
      </section>
      <section>
        <SectionHeader>
          <SectionTitle>{{ $t("accommodations.manage.contracts.title") }}</SectionTitle>
          <div>
            <ButtonWithIcon
              class="btn-white"
              icon="file-contract-solid"
              @click="accommodationContractCreateModal?.open()"
            >
              {{ $t("accommodations.manage.contracts.create") }}
            </ButtonWithIcon>
          </div>
        </SectionHeader>
        <AccommodationsContracts
          ref="accommodationContractsElement"
          :accommodation="showAccommodationRequest.data"
          @confirmed="accommodationsCalendarElement?.fetch()"
        />
      </section>
      <section>
        <SectionHeader>
          <SectionTitle>{{ $t("accommodations.manage.calendar.title") }}</SectionTitle>
          <div>
            <ButtonWithIcon
              class="btn-white"
              icon="calendar-days-solid"
              @click="accommodationUpdateAvailabilityModal?.open()"
            >
              {{ $t("accommodations.availability.update.title") }}
            </ButtonWithIcon>
          </div>
        </SectionHeader>
        <AccommodationsCalendar
          ref="accommodationsCalendarElement"
          :accommodation="showAccommodationRequest.data"
        />
      </section>
      <section>
        <SectionHeader>
          <SectionTitle>{{ $t("accommodations.manage.bookings.title") }}</SectionTitle>
        </SectionHeader>
        <AccommodationsBookings :accommodation="showAccommodationRequest.data" />
      </section>
    </SectionGroup>
  </template>
  <ModalDialog
    ref="attachmentCreateModal"
    v-slot="{ close }"
  >
    <AccommodationAttachmentsCreateModal
      :accommodation-id="showAccommodationRequest.data.id"
      @close="close"
      @created="getAccommodationAttachments.fetch()"
    />
  </ModalDialog>
  <ModalDialog
    ref="photosCreateModal"
    v-slot="{ close }"
    size="3xl"
  >
    <AccommodationPhotosCreateModal
      :accommodation="showAccommodationRequest.data"
      @close="close"
      @uploaded="(value: AccommodationPhoto) => showAccommodationRequest.data.photos?.push(value)"
    />
  </ModalDialog>
  <ModalDialog
    ref="accommodationUpdateDetailsModal"
    v-slot="{ close }"
    size="5xl"
  >
    <AccommodationsUpdateDetailsModal
      :accommodation="showAccommodationRequest.data"
      @close="close"
      @updated="showAccommodationRequest.data = $event"
    />
  </ModalDialog>
  <ModalDialog
    ref="accommodationUpdateBedroomsModal"
    v-slot="{ close }"
    size="3xl"
  >
    <AccommodationsUpdateBedroomsModal
      :accommodation="showAccommodationRequest.data"
      :bedrooms="showAccommodationRequest.data.bedrooms"
      @close="close"
      @updated="showAccommodationRequest.data = $event"
    />
  </ModalDialog>
  <ModalDialog
    ref="accommodationUpdateBathroomsModal"
    v-slot="{ close }"
    size="3xl"
  >
    <AccommodationsUpdateBathroomsModal
      :accommodation="showAccommodationRequest.data"
      :bathrooms="showAccommodationRequest.data.bathrooms"
      @close="close"
      @updated="showAccommodationRequest.data = $event"
    />
  </ModalDialog>
  <ModalDialog
    ref="accommodationUpdateKitchensModal"
    v-slot="{ close }"
    size="large"
  >
    <AccommodationsUpdateKitchensModal
      :accommodation="showAccommodationRequest.data"
      :kitchens="showAccommodationRequest.data.kitchens"
      @close="close"
      @updated="showAccommodationRequest.data = $event"
    />
  </ModalDialog>
  <ModalDialog
    ref="accommodationUpdateAddressModal"
    v-slot="{ close }"
    size="5xl"
  >
    <AccommodationsUpdateAddressModal
      :accommodation="showAccommodationRequest.data"
      @close="close"
      @updated="showAccommodationRequest.data = $event"
    />
  </ModalDialog>
  <ModalDialog
    ref="accommodationUpdateDescriptionsModal"
    v-slot="{ close }"
    size="5xl"
  >
    <AccommodationsUpdateDescriptionsModal
      :accommodation="showAccommodationRequest.data"
      :descriptions="showAccommodationRequest.data.descriptions ?? []"
      @close="close"
      @updated="showAccommodationRequest.data = $event"
    />
  </ModalDialog>
  <ModalDialog
    ref="accommodationUpdateExtrasModal"
    v-slot="{ close }"
    size="7xl"
  >
    <AccommodationsUpdateExtrasModal
      :accommodation="showAccommodationRequest.data"
      :extras="showAccommodationRequest.data.extras ?? []"
      @close="close"
      @updated="showAccommodationRequest.data = $event"
    />
  </ModalDialog>
  <ModalDialog
    ref="accommodationUpdateFacilitiesModal"
    v-slot="{ close }"
    size="7xl"
  >
    <AccommodationsUpdateFacilitiesModal
      :accommodation="showAccommodationRequest.data"
      @close="close"
      @updated="showAccommodationRequest.data = $event"
    />
  </ModalDialog>
  <ModalDialog
    ref="accommodationContractCreateModal"
    v-slot="{ close }"
  >
    <AccommodationContractsCreateModal
      :accommodation="showAccommodationRequest.data"
      @close="close"
      @created="accommodationContractsElement?.fetch()"
    />
  </ModalDialog>
  <ModalDialog
    ref="accommodationUpdateAvailabilityModal"
    v-slot="{ close }"
    size="medium"
  >
    <AccommodationsUpdateAvailabilityModal
      :accommodation="showAccommodationRequest.data"
      @close="close"
      @updated="accommodationsCalendarElement?.fetch()"
    />
  </ModalDialog>
  <RouterView
    @fetch-accommodation="showAccommodationRequest.fetch()"
    @fetch-contracts="accommodationContractsElement?.fetch()"
  />
</template>

<script lang="ts" setup>
import LoadingSection from "@/contexts/shared/ui/components/loading/LoadingSection.vue";
import ListErrorState from "@/contexts/shared/ui/components/list/ListErrorState.vue";
import DashboardHeader from "@/contexts/shared/ui/components/header/DashboardHeader.vue";
import DashboardBackTo from "@/contexts/shared/ui/components/header/DashboardBackTo.vue";
import DashboardHeaderTitle from "@/contexts/shared/ui/components/header/DashboardHeaderTitle.vue";
import DashboardHeaderActions from "@/contexts/shared/ui/components/header/DashboardHeaderActions.vue";
import ClipboardCopyId from "@/contexts/shared/ui/components/clipboard/ClipboardCopyId.vue";
import SectionGroup from "@/contexts/shared/ui/components/section/SectionGroup.vue";
import useRequest from "@/contexts/shared/composables/useRequest";
import { useEventBus } from "@vueuse/core";
import type { Accommodation } from "@/contexts/accommodations/models/Accommodation";
import useAccommodations from "@/contexts/accommodations/composables/useAccommodations";
import SectionHeader from "@/contexts/shared/ui/components/section/SectionHeader.vue";
import SectionTitle from "@/contexts/shared/ui/components/section/SectionTitle.vue";
import ButtonWithIcon from "@/contexts/shared/ui/components/button/ButtonWithIcon.vue";
import AccommodationAttachmentsTable from "@/contexts/accommodation-attachments/ui/components/AccommodationAttachmentsTable.vue";
import type { AccommodationAttachment } from "@/contexts/accommodation-attachments/models/AccommodationAttachment";
import useAccommodationAttachments from "@/contexts/accommodation-attachments/composables/useAccommodationAttachments";
import AccommodationsDropdown from "@/contexts/accommodations/ui/components/AccommodationsDropdown.vue";
import Errors from "@/contexts/shared/models/Errors";
import ModalDialog from "@/contexts/shared/ui/components/modal/ModalDialog.vue";
import AccommodationAttachmentsCreateModal from "@/contexts/accommodation-attachments/ui/modals/AccommodationAttachmentsCreateModal.vue";
import AccommodationsUpdateDetailsModal from "@/contexts/accommodations/ui/modals/AccommodationsUpdateDetailsModal.vue";
import AccommodationsUpdateAddressModal from "@/contexts/accommodations/ui/modals/AccommodationsUpdateAddressModal.vue";
import FontAwesomeIcon from "@/contexts/shared/ui/components/icon/FontAwesomeIcon.vue";
import AccommodationsAddress from "@/contexts/accommodations/ui/components/AccommodationsAddress.vue";
import AccommodationsDetails from "@/contexts/accommodations/ui/components/AccommodationsDetails.vue";
import AccommodationsBookings from "@/contexts/accommodations/ui/components/AccommodationsBookings.vue";
import AccommodationsCalendar from "@/contexts/accommodations/ui/components/AccommodationsCalendar.vue";
import SharedAlert from "@/contexts/shared/ui/components/alert/SharedAlert.vue";
import ListDetailsColumn from "@/contexts/shared/ui/components/list/ListDetailsColumn.vue";
import ListDetailsCell from "@/contexts/shared/ui/components/list/ListDetailsCell.vue";
import ListDetails from "@/contexts/shared/ui/components/list/ListDetails.vue";
import AccommodationsContracts from "@/contexts/accommodations/ui/components/AccommodationsContracts.vue";
import AccommodationContractsCreateModal from "@/contexts/accommodation-contracts/ui/modals/AccommodationContractsCreateModal.vue";
import AccommodationPhotosCreateModal from "@/contexts/accommodation-photos/ui/modals/AccommodationPhotosCreateModal.vue";
import type { AccommodationPhoto } from "@/contexts/accommodation-photos/models/AccommodationPhoto";
import AccommodationsBedrooms from "@/contexts/accommodations/ui/components/AccommodationsBedrooms.vue";
import AccommodationsBathrooms from "@/contexts/accommodations/ui/components/AccommodationsBathrooms.vue";
import AccommodationsKitchens from "@/contexts/accommodations/ui/components/AccommodationsKitchens.vue";
import AccommodationsUpdateBedroomsModal from "@/contexts/accommodations/ui/modals/AccommodationsUpdateBedroomsModal.vue";
import AccommodationsUpdateBathroomsModal from "@/contexts/accommodations/ui/modals/AccommodationsUpdateBathroomsModal.vue";
import AccommodationsUpdateKitchensModal from "@/contexts/accommodations/ui/modals/AccommodationsUpdateKitchensModal.vue";
import AccommodationPhotos from "@/contexts/accommodation-photos/ui/components/AccommodationPhotos.vue";
import AccommodationsUpdateFacilitiesModal from "@/contexts/accommodations/ui/modals/AccommodationsUpdateFacilitiesModal.vue";
import AccommodationsFacilities from "@/contexts/accommodations/ui/components/AccommodationsFacilities.vue";
import useAccommodationPhotos from "@/contexts/accommodation-photos/composables/useAccommodationPhotos";
import useFormatter from "@/contexts/shared/composables/useFormatter";
import useNotification from "@/contexts/shared/composables/useNotification";
import { snakeCase } from "lodash-es";
import DropdownMenuButton from "@/contexts/shared/ui/components/dropdown/DropdownMenuButton.vue";
import AccommodationsDescriptions from "@/contexts/accommodations/ui/components/AccommodationsDescriptions.vue";
import AccommodationsExtras from "@/contexts/accommodations/ui/components/AccommodationsExtras.vue";
import AccommodationsUpdateDescriptionsModal from "@/contexts/accommodations/ui/modals/AccommodationsUpdateDescriptionsModal.vue";
import AccommodationsUpdateExtrasModal from "@/contexts/accommodations/ui/modals/AccommodationsUpdateExtrasModal.vue";
import AccommodationsUpdateAvailabilityModal from "@/contexts/accommodations/ui/modals/AccommodationsUpdateAvailabilityModal.vue";

const { t: $t } = useI18n();
const route = useRoute();
const router = useRouter();
const { b64toBlob } = useFormatter();
const { errorNotification } = useNotification();
const { showAccommodation } = useAccommodations();
const { getAttachments } = useAccommodationAttachments();
const { exportAccommodationPhotos } = useAccommodationPhotos();
const accountBus = useEventBus<string>("account");

accountBus.on(() => router.push({ name: "accommodations" }));
useHead({ title: () => `${$t("accommodations.manage.title")} - Hussbook` });

const accommodationId = computed<string>(() => String(route.params.id));

const photosCreateModal = ref<InstanceType<typeof ModalDialog>>();
const attachmentCreateModal = ref<InstanceType<typeof ModalDialog>>();
const accommodationUpdateBedroomsModal = ref<InstanceType<typeof ModalDialog>>();
const accommodationUpdateBathroomsModal = ref<InstanceType<typeof ModalDialog>>();
const accommodationUpdateKitchensModal = ref<InstanceType<typeof ModalDialog>>();
const accommodationUpdateDetailsModal = ref<InstanceType<typeof ModalDialog>>();
const accommodationUpdateAddressModal = ref<InstanceType<typeof ModalDialog>>();
const accommodationContractCreateModal = ref<InstanceType<typeof ModalDialog>>();
const accommodationUpdateFacilitiesModal = ref<InstanceType<typeof ModalDialog>>();
const accommodationUpdateDescriptionsModal = ref<InstanceType<typeof ModalDialog>>();
const accommodationUpdateExtrasModal = ref<InstanceType<typeof ModalDialog>>();
const accommodationUpdateAvailabilityModal = ref<InstanceType<typeof ModalDialog>>();
const accommodationsCalendarElement = ref<InstanceType<typeof AccommodationsCalendar>>();
const accommodationContractsElement = ref<InstanceType<typeof AccommodationsContracts>>();

onMounted(() => {
  showAccommodationRequest.fetch();
});

const showAccommodationRequest = useRequest<Accommodation>({
  initialLoading: true,
  promise: () => showAccommodation(accommodationId.value),
  onFailure: (e) => {
    if (e === Errors.NOT_FOUND) {
      router.push({ name: "accommodations" });
      return;
    }
  },
});
const getAccommodationAttachments = useRequest<AccommodationAttachment[]>({
  promise: () => getAttachments(accommodationId.value),
  onSuccess: (response) => (showAccommodationRequest.data.attachments = response),
});
const exportAccommodationPhotosRequest = useRequest({
  promise: () => exportAccommodationPhotos(accommodationId.value),
  onSuccess: (data) => {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(b64toBlob(data, "application/zip"));
    link.download = `${snakeCase(showAccommodationRequest.data.name)}_photos.zip`;
    link.target = "_blank";
    link.click();
    link.remove();
  },
  onFailure: () => {
    errorNotification("accommodation_photos.export.error");
  },
});
</script>
