<template>
  <div class="py-1.5 text-sm lg:grid lg:grid-cols-3 lg:gap-4 lg:px-2 lg:py-1">
    <div class="text-sm font-medium text-gray-500">
      {{ $t(label) }}
    </div>
    <div class="mt-1 flex text-sm text-gray-800 lg:col-span-2 lg:mt-0">
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  label: string;
}>();

const { t: $t } = useI18n();
</script>
