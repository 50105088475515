<template>
  <div class="flex h-screen min-h-full flex-col pb-12 pt-16">
    <main class="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
      <div class="flex shrink-0 justify-center">
        <a
          class="mb-6 flex items-center justify-start px-6"
          href="https://www.hussbook.com"
        >
          <BrandIcon
            class="h-20 w-auto"
            icon="icon"
          />
          <div class="ml-4 text-4xl font-bold">Hussbook</div>
        </a>
      </div>
      <div class="py-16">
        <div class="text-center">
          <h1 class="mt-2 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            {{ $t("shared.not_found.title") }}
          </h1>
          <p class="mt-2 text-base text-gray-500">
            {{ $t("shared.not_found.description") }}
          </p>
          <div class="mt-6">
            <RouterLink
              :to="{ name: 'dashboard' }"
              class="text-base font-medium text-primary hover:text-primary"
            >
              {{ $t("shared.not_found.to_index") }}
              <span aria-hidden="true"> &rarr;</span>
            </RouterLink>
          </div>
        </div>
      </div>
    </main>
    <footer class="mx-auto w-full max-w-7xl shrink-0 px-4 sm:px-6 lg:px-8">
      <nav class="flex justify-center space-x-4">
        <a
          class="text-gray-600 hover:text-gray-800"
          href="https://www.hussbook.com"
          target="_blank"
        >
          © {{ year }} Hussbook
        </a>
        <span class="inline-block border-l border-gray-300" />
        <a
          class="text-gray-600 hover:text-gray-800"
          href="https://www.hussbook.com/contact"
          target="_blank"
        >
          {{ $t("shared.not_found.footer.contact") }}
        </a>
        <span class="inline-block border-l border-gray-300" />
        <a
          class="text-gray-600 hover:text-gray-800"
          href="https://www.hussbook.com/contact"
          target="_blank"
        >
          {{ $t("shared.not_found.footer.privacy_terms") }}
        </a>
      </nav>
    </footer>
  </div>
</template>

<script lang="ts" setup>
import BrandIcon from "@/contexts/shared/ui/components/icon/BrandIcon.vue";

const { t: $t } = useI18n();

const year = computed(() => new Date().getFullYear());
</script>
