<template>
  <DashboardHeader>
    <DashboardBackTo :to="{ name: 'bookings' }">
      {{ $t("bookings.manage.back_to_list") }}
    </DashboardBackTo>
    <DashboardHeaderTitle>{{ $t("booking_imports.title") }}</DashboardHeaderTitle>
    <DashboardHeaderActions>
      <RouterLinkWithIcon
        :to="{ name: 'bookings.imports.create' }"
        class="btn-primary"
        icon="plus-solid"
      >
        {{ $t("booking_imports.create.title") }}
      </RouterLinkWithIcon>
    </DashboardHeaderActions>
  </DashboardHeader>
  <div class="space-y-3">
    <FilterGroup
      :is-empty="criteriaEmpty"
      @clear="clearCriteria"
    >
      <FilterItem
        v-model="criteria.type"
        label="booking_imports.filters.type"
        title="booking_imports.filters_by.type"
      >
        <FilterRadio :options="bookingImportTypeOptions" />
      </FilterItem>
      <FilterItem
        v-model="criteria.provider"
        label="booking_imports.filters.provider"
        title="booking_imports.filters_by.provider"
      >
        <FilterOptions :options="bookingImportProviderOptions" />
      </FilterItem>
      <FilterItem
        v-model="criteria.status"
        label="booking_imports.filters.status"
        title="booking_imports.filters_by.status"
      >
        <FilterOptions :options="bookingImportStatusOptions" />
      </FilterItem>
    </FilterGroup>
    <template v-if="searchBookingImportsRequest.isLoading">
      <LoadingSection />
    </template>
    <template v-else-if="searchBookingImportsRequest.hasErrors">
      <ListErrorState />
    </template>
    <template v-else-if="searchBookingImportsRequest.data.data.length === 0">
      <ListEmptyState
        description="booking_imports.empty.description"
        icon="file-arrow-up-solid"
        title="booking_imports.empty.title"
      >
        <div class="space-x-5">
          <RouterLinkWithIcon
            :to="{ name: 'bookings.imports.create' }"
            class="btn-primary"
            icon="plus-solid"
          >
            {{ $t("booking_imports.create.title") }}
          </RouterLinkWithIcon>
        </div>
      </ListEmptyState>
    </template>
    <template v-else>
      <BookingImportsTable
        :booking-imports="searchBookingImportsRequest.data.data"
        @fetch-list="searchBookingImportsRequest.fetch()"
      />
      <CursorPagination
        :meta="searchBookingImportsRequest.data.meta"
        :total="searchBookingImportsRequest.data.total"
        @change-page="changePage"
      />
    </template>
  </div>
  <RouterView @fetch-list="changePage" />
</template>

<script setup lang="ts">
import useBookingImports from "@/contexts/booking-imports/composables/useBookingImports";
import useRequest, { type UseRequest } from "@/contexts/shared/composables/useRequest";
import LoadingSection from "@/contexts/shared/ui/components/loading/LoadingSection.vue";
import ListErrorState from "@/contexts/shared/ui/components/list/ListErrorState.vue";
import DashboardHeader from "@/contexts/shared/ui/components/header/DashboardHeader.vue";
import DashboardBackTo from "@/contexts/shared/ui/components/header/DashboardBackTo.vue";
import DashboardHeaderTitle from "@/contexts/shared/ui/components/header/DashboardHeaderTitle.vue";
import useCriteria from "@/contexts/shared/composables/useCriteria";
import type { SearchBookingImportCriteria } from "@/contexts/booking-imports/models/SearchBookingImportCriteria";
import { emptyPagination, type Pagination } from "@/contexts/shared/models/Pagination";
import type { BookingImport } from "@/contexts/booking-imports/models/BookingImport";
import RouterLinkWithIcon from "@/contexts/shared/ui/components/button/RouterLinkWithIcon.vue";
import DashboardHeaderActions from "@/contexts/shared/ui/components/header/DashboardHeaderActions.vue";
import { useEventBus } from "@vueuse/core";
import FilterGroup from "@/contexts/shared/ui/components/filter/FilterGroup.vue";
import ListEmptyState from "@/contexts/shared/ui/components/list/ListEmptyState.vue";
import CursorPagination from "@/contexts/shared/ui/components/pagination/CursorPagination.vue";
import FilterOptions from "@/contexts/shared/ui/components/filter/FilterOptions.vue";
import FilterItem from "@/contexts/shared/ui/components/filter/FilterItem.vue";
import BookingImportsTable from "@/contexts/booking-imports/ui/components/BookingImportsTable.vue";
import FilterRadio from "@/contexts/shared/ui/components/filter/FilterRadio.vue";

const { t: $t } = useI18n();
const { searchBookingImports, bookingImportTypeOptions, bookingImportProviderOptions, bookingImportStatusOptions } = useBookingImports();

const accountBus = useEventBus<string>("account");

accountBus.on(() => clearCriteria());
useHead({ title: () => `${$t("booking_imports.title")} - Hussbook` });

onMounted(() => searchBookingImportsRequest.fetch());

const { criteria, cursor, changePage, criteriaEmpty, clearCriteria } = useCriteria<SearchBookingImportCriteria>({}, () => searchBookingImportsRequest.fetch());

const searchBookingImportsRequest: UseRequest<Pagination<BookingImport>> = useRequest<Pagination<BookingImport>>({
  initialLoading: true,
  value: emptyPagination<BookingImport>(),
  promise: () => searchBookingImports(criteria.value, cursor.value),
});
</script>
