import type { Option } from "@/contexts/shared/models/Select";

export default function useFeatured() {
  const { t: $t } = useI18n();

  const featuredOptions: Option[] = [
    { value: true, label: $t("shared.featured") },
    { value: false, label: $t("shared.not_featured") },
  ];

  return {
    featuredOptions,
  };
}
