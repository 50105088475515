import api from "@/libs/api";
import SearchBookingImportsFactory from "@/contexts/booking-imports/composables/factories/SearchBookingImportsFactory";
import { formDataBodySerializer } from "@/contexts/shared/utils/formDataBodySerializer";
import CreateBookingImportFactory from "@/contexts/booking-imports/composables/factories/CreateBookingImportFactory";
import type { BookingImportProvider } from "@/contexts/booking-imports/models/BookingImport";
import type { SearchBookingImportCriteria } from "@/contexts/booking-imports/models/SearchBookingImportCriteria";
import type { Option } from "@/contexts/shared/models/Select";
import BookingImportProviderProvider from "@/contexts/booking-imports/providers/bookingImportProviderProvider";
import BookingImportStatusProvider from "@/contexts/booking-imports/providers/bookingImportStatusProvider";
import BookingImportTypeProvider from "@/contexts/booking-imports/providers/bookingImportTypeProvider";

export default function useBookingImports() {
  const { t: $t } = useI18n();

  const searchBookingImports = async (criteria: SearchBookingImportCriteria, cursor?: string) => {
    const { data, response } = await api.GET("/bookings/imports", { params: { query: SearchBookingImportsFactory.toSearchBookingImportsRequest(criteria, cursor) } });
    return SearchBookingImportsFactory.fromSearchBookingImportsResponse(data, response.headers);
  };
  const createBookingImport = async (bookingImport: { provider: BookingImportProvider | undefined; file: File | undefined }) => {
    return await api.POST("/bookings/imports", {
      body: CreateBookingImportFactory.toCreateBookingImportRequestFactory(bookingImport),
      bodySerializer: formDataBodySerializer,
    });
  };

  const bookingImportProviderOptions: Option[] = BookingImportProviderProvider.get().map((item) => ({ value: item, label: $t(`booking_imports.providers.${item}`) }));
  const bookingImportStatusOptions: Option[] = BookingImportStatusProvider.get().map((item) => ({ value: item, label: $t(`booking_imports.status.${item}`) }));
  const bookingImportTypeOptions: Option[] = BookingImportTypeProvider.get().map((item) => ({ value: item, label: $t(`booking_imports.type.${item}`) }));

  return {
    searchBookingImports,
    createBookingImport,
    bookingImportProviderOptions,
    bookingImportStatusOptions,
    bookingImportTypeOptions,
  };
}
